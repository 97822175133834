/* About us */

.about-section {
    background-color: #ef8b33;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 177px 0 54px;
}

.about-section::before {
    content: "";
    width: 70%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 0 0 600px;
    background-color: #010521;
}

.about-section::after {
    content: "";
    background-image: url('/public/about-background.jpg');
    border-radius: 0 0 0 600px;
    width: 70%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 10%;
}

.about-section .about-rightimage {
    position: absolute;
    right: -150px;
    top: 254px;
    z-index: 1;
}

.about-section .about-rightcircle {
    position: absolute;
    right: 170px;
    bottom: 212px;
    z-index: 1;
}

.about-section .about_wrapper {
    position: relative;
}

.about-section .about_wrapper .about-image1 {
    position: absolute;
    left: -60px;
    top: -118px;
    z-index: 1;
}

.about-section .about_wrapper .about-image1 img {
    border-radius: 0 200px 200px 200px;
    /* border: 2px solid green; */
    height: 476px;
    width: 550px;
}

.about-section .about_wrapper .about-image2 {
    position: absolute;
    left: -108px;
    top: 180px;
    z-index: 1;
}

.about-section .about_wrapper .about-image2 img {
    border-radius: 100px 0 100px 100px;
    border-top: 5px solid white;
    border-right: 5px solid white;
    margin-top: -40px;
}

.about-section .about_wrapper .years {
    text-align: center;
    position: relative;
    z-index: 2;
    right: -216px;
    top: -84px;
}

.about-section .about_wrapper .years .value {
    font-size: 56px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 8px;
    display: inline-block;
}

.about-section .about_wrapper .years .plus {
    font-size: 34px;
    line-height: 34px;
    font-weight: 700;
    display: inline-block;
}

.about-section .about_wrapper .years .text {
    font-size: 18px;
    line-height: 17px;
    font-weight: 600;
    display: block;
    padding: 0 200px;
}

.about-section .about_wrapper .about-imagecircle {
    position: absolute;
    right: -22px;
    top: -130px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
}

.about-section .about_wrapper .years:hover .about-imagecircle {
    transform: translateY(-5px);
}

.about-section .about_wrapper .about-dot {
    position: absolute;
    left: -110px;
    top: -200px;
}

.about-section .about_wrapper .about-leftcircle {
    position: absolute;
    left: -106px;
    top: 170px;
}

.about-section .about_wrapper .about-layer {
    position: absolute;
    left: -480px;
    top: 240px;
}

.about-section .about_content {
    padding-left: 55px;
    position: relative;
    top: -80px;
    z-index: 1;
}

.about-section .about_content h2 {
    margin-bottom: 16px;
    margin-right: -12px;
}

.about-section .about_content p {
    color: white;
}


.about-section .about_content h3 {
    color: #ef8b33;
}
.about-section .about_content .text1 {
    margin-bottom: 8px;
}

.about-section .about_content .text2 {
    margin-bottom: 38px;
}

.about-section .about_content .read_more {
    background-color: #ef8b33;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    border-radius: 30px;
    padding: 15px 25px 15px 43px;
    text-align: center;
    display: inline-block;
    transition: all 0.3s ease-in-out;
}

.about-section .about_content .read_more figure {
    display: inline-block;
    margin-left: 20px;
}

.about-section .about_content .read_more:hover {
    background-color: #010521;
}

@media only screen and (max-width: 1278px) {
.about-section {
    padding: 64px 0 20px;
    }
 
    .about-section .about_wrapper {
        text-align: center;
    }
    .about-section .about_wrapper .about-image1 {
        position: relative;
        left: -40px;
        top: -20px;
    }
    .about-section .about_wrapper .about-image2 {
        left: -40px;
        top: 275px
    }
    .about-section .about_wrapper .years {
        right: -194px;
        top: -720px;
    }
    .about-section .about_wrapper .years .value {
        font-size: 36px;
        line-height: 36px;
    }
    .about-section .about_wrapper .years .plus {
        font-size: 28px;
        line-height: 28px;
    }
    .about-section .about_wrapper .years .text {
        font-size: 16px;
        line-height: 16px;
        padding: 0 290px;
    }
    .about-section .about_wrapper .about-imagecircle {
        right: 96px;
    }
    .about-section .about_wrapper .about-imagecircle img {
        width: 114px;
    }
    .about-section .about_wrapper .about-dot {
        left: -88px;
        top: -82px;
    }
    .about-section .about_wrapper .about-dot img {
        width: 100px;
    }
    .about-section .about_wrapper .about-leftcircle {
        display: none;
    }
    .about-section .about_wrapper .about-layer img {
        width: 270px;
    }
    .about-section .about_content {
        top: 30px;
        text-align: left;
        padding-left: 0;
        margin-bottom: 120px;
    }
    .about-section .about_content h3 {
        margin-bottom: 4px;
        padding: 0 50px;
    }
    .about-section .about_content h2 {
        margin-bottom: 10px;
        padding: 0 50px;
    }
    .about-section .about_content .text1 {
        margin-bottom: 10px;
        padding: 0 50px;
    }
    .about-section .about_content .text2 {
        margin-bottom: 25px;
        padding: 0 50px;
    }
    .about-section .about_content .read_more {
        font-size: 16px;
        line-height: 24px;
        position: relative;
        left: 50px;
        padding: 10px 20px;
    }
    .about-section .about_content .read_more:hover {
        background-color:#010521 ;
    }

    .about-section .about-rightimage{
        display: none;
    } 
}



@media only screen and (max-width: 991px) {
    .about-section {
        padding: 64px 0 10px;
    }
    .about-section::before {
        width: 100%;
    }
    .about-section::after {
        width: 100%;
    }
    .about-section .about_wrapper {
        text-align: center;
    }
    .about-section .about_wrapper .about-image1 {
        position: relative;
        left: 20px;
        top: -50px;
    }
    .about-section .about_wrapper .about-image2 {
        left: 110px;
        top: 250px
    }
    .about-section .about_wrapper .years {
        right: -194px;
        top: -720px;
    }
    .about-section .about_wrapper .years .value {
        font-size: 36px;
        line-height: 36px;
    }
    .about-section .about_wrapper .years .plus {
        font-size: 28px;
        line-height: 28px;
    }
    .about-section .about_wrapper .years .text {
        font-size: 16px;
        line-height: 16px;
        padding: 0 290px;
    }
    .about-section .about_wrapper .about-imagecircle {
        right: 96px;
    }
    .about-section .about_wrapper .about-imagecircle img {
        width: 114px;
    }
    .about-section .about_wrapper .about-dot {
        left: 98px;
        top: -102px;
    }
    .about-section .about_wrapper .about-dot img {
        width: 100px;
    }
    .about-section .about_wrapper .about-leftcircle {
        display: none;
    }
    .about-section .about_wrapper .about-layer img {
        width: 270px;
    }
    .about-section .about_content {
        text-align: center;
        padding-left: 0;
        margin-bottom: 120px;
    }
    .about-section .about_content h6 {
        margin-bottom: 4px;
    }
    .about-section .about_content h2 {
        margin-bottom: 10px;
        padding: 0 130px;
    }
    .about-section .about_content .text1 {
        margin-bottom: 10px;
        padding: 0 95px;
    }
    .about-section .about_content .text2 {
        margin-bottom: 25px;
        padding: 0 90px;
    }
    .about-section .about_content .read_more {
        font-size: 16px;
        line-height: 24px;
        padding: 10px 20px;
    }
    .about-section .about_content .read_more:hover {
        background-color:#010521 ;
    }

    .about-section .about-rightimage{
        display: none;
    } 
}

@media only screen and (max-width: 768px) {
    .about-section {
        padding: 1px 0 50px;
    }
    .about-section .about-backgroundimage {
        right: -680px;
    }
    .about-section .about_wrapper .about-image1 {
        left: -7px;
        top: 0px;
    }
    .about-section .about_wrapper .about-image1 img {
        width: 350px;
        height: 420px;
        border-radius: 0 135px 135px 135px;
    }
    .about-section .about_wrapper .about-image2 {
        left: 0px;
        top: 330px;
    }
    .about-section .about_wrapper .about-image2 img {
        width: 170px;
        border-radius: 65px 0 65px 65px;
    }
    .about-section .about_wrapper .years{
        right: -120px;
        top: -453px;
    }
    .about-section .about_wrapper .years .value {
        font-size: 30px;
        line-height: 30px;
    }
    .about-section .about_wrapper .years .plus {
        font-size: 24px;
        line-height: 24px;
    }
    .about-section .about_wrapper .years .text {
        font-size: 14px;
        line-height: 14px;
        padding: 0 210px;
        position: relative;
        top:-10px;
        right: 51px;
    }
    .about-section .about_wrapper .about-imagecircle {
        right: 40px;
        top: -56px;
    }
    .about-section .about_wrapper .about-imagecircle img {
        width: 100px;
    }
    .about-section .about_wrapper .about-dot {
        left: 10px;
        top: -59px;
    }
    .about-section .about-rightcircle {
        display: none;
    }
    .about-section .about_content {
        margin-bottom: 114px;
    }
    .about-section .about_content h2 {
        padding: 0 80px;
    }
    .about-section .about_content .text1 {
        margin-bottom: 10px;
        padding: 0 10px;
    }
    .about-section .about_content .text2 {
        margin-bottom: 20px;
        padding: 0;
    }
    .about-section .about_content .read_more {
        font-size: 14px;
        line-height: 20px;
        padding: 10px 24px;
        position: relative;
        left: 10px;
    }

    .about-section .about_content .read_more:hover {
        background-color:#010521 ;
    }
    .about-section .about_content .read_more figure {
        margin-left: 6px;
    }
    .about-section .about_content .read_more figure img {
        width: 16px;
    }

    .about-section .about-rightimage{
        display: none;
    } 
}

@media (min-width:769px) and (max-width:880px) {
    .about-section {
        padding: 10px 0 20px;
    }
    .about-section .about_content .read_more {
        margin-left: -120px;
    }
}

@media (min-width:820px) and (max-width:1280px) {
    .about-section .about_wrapper .about-image1 {
        left: -17px;
        top: -130px;
    }
    .about-section .about_wrapper .about-image2 {
        left: -10px;
        top: 190px;
    }
}
@media (min-width: 1367px) and (max-width: 1920px) {
    .about-section {
        padding: 180px 0 150px;
    }
    .about-section .about_wrapper .about-image1 {
        top: -70px;
        left: -46px;
        }
    .about-section .about_wrapper .about-image2 {
    top: 240px;
    left: -80px;
    }
  }
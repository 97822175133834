/* Offer */

.offer-section {
  background-color: var(--e-global-color-very-light-gray-mostly-white);
  position: relative;
  padding: 60px 0;
}

.offer-section .offer-toplayer {
  position: absolute;
  left: -24px;
  top: 0;
}

.offer-section .offer-bottomlayer {
  position: absolute;
  right: 0;
  bottom: 0;
}

.offer-section .offer-circleimage {
  position: absolute;
  left: -146px;
  bottom: 70px;
}

.offer-section .offer_boxcontent {
  box-shadow: 1px 1px 50px rgb(0 0 0 / 10%);
  width: 100%;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  background: white;
  transition: all 0.3s ease-in-out;
}

.offer-section .offer_boxcontent:hover {
  background: #010521;
}

.offer-section .offer_boxcontent:hover h4 {
  color: white;
}
.offer-section .offer_boxcontent:hover p {
  color: white;
}

.offer-section .offer_content {
  text-align: center;
}

.offer-section .offer_content h2 {
  margin-bottom: 18px;
}

.offer-section .offer_content p {
  margin-bottom: 42px;
  padding: 0 135px;
}

.offer-section .offer_boxcontent .upper_portion {
  position: relative;
}

.offer-section .offer_boxcontent .upper_portion .article_img figure img {
  width: 100%;
  height: 240px;
  border-radius: 10px 10px 0 0;
}

.offer-section .offer_boxcontent .upper_portion .image_content {
  background-color: #fa6220;
  box-shadow: 8px 2px 20px rgb(0 0 0 / 10%);
  border-radius: 100px;
  height: 105px;
  width: 105px;
  line-height: 105px;
  text-align: center;
  left: 120px;
  bottom: -50px;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.offer-section .offer_boxcontent .upper_portion .image_content:hover {
  background-image: linear-gradient(
    to right,
    var(--e-button-gradient-2) 0%,
    var(--e-button-gradient-1) 100%
  );
  transform: translateY(-5px);
}

.offer-section .offer_boxcontent .lower_portion_wrapper {
  text-align: center;
  padding: 70px 45px 36px;
  border-radius: 0 0 10px 10px;
  border-top: none;
  height: 250px;
}

.offer-section .offer_boxcontent .lower_portion_wrapper:hover {
  color: white;
}
@media (max-width: 768px) {
  .article_padding {
    margin-bottom: 40px;
  }
  .offer-section .offer_boxcontent .upper_portion .image_content {
    left: 115px;
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .article_padding {
    margin-bottom: 40px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .offer-section .offer_boxcontent .lower_portion_wrapper {
    height: 290px;
  }
  .offer-section .offer_boxcontent .upper_portion .image_content {
    /* border: 3px solid; */
    left: 100px;
    bottom: -40px;
  }
}
@media (min-width:769px) and (max-width:880px) {
  .offer-section .offer_boxcontent .upper_portion .image_content {
    left: 113px;
    bottom: -45px;
  }
}
@media (min-width:882px) and (max-width:1280px) {
  .offer-section .offer_boxcontent .upper_portion .image_content {
    left: 120px;
    bottom: -45px;
  }
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .offer-section .offer_boxcontent .upper_portion .image_content {
    left: 150px;
  }
}

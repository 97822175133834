
.testimonial-section {
    padding: 150px 0 10px;
    overflow: hidden;
}

.testimonial-section .service-leftlayer {
    position: absolute;
    top: 0;
    left: 116px;
}

.testimonial-section .service-rightlayer {
    position: absolute;
    bottom: -130px;
    right: -60px;
}

.testimonial-section .testimonial_wrapper .service-rightcircle {
    position: absolute;
    top: -42px;
    left: 0;
}

.testimonial-section .testimonial_wrapper .circle_wrapper {
    height: 380px;
    width: 380px;
    border-radius: 100%;
    margin-left: -6px;
    margin-top: 18px;
    background: #fffaf8;
}

.testimonial-section .testimonial_wrapper .circle_wrapper .center-circle {
    height: 155px;
    width: 155px;
    border-radius: 100%;
    background: #ffffff;
    position: relative;
    left: -4px;
    right: 0;
    margin: 0 auto;
    top: 120px;
}

.testimonial-section .testimonial_wrapper .circle_wrapper figure img {
    border-radius: 100px;
}

.testimonial-section .testimonial_wrapper .circle_wrapper .center-circle .testimonial-centerimage {
    position: absolute;
    top: 6px;
    left: -4px;
    transition: all 0.3s ease-in-out;
}

.testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image1 {
    right: -54px;
    top: -78px;
    transition: all 0.3s ease-in-out;
}

.testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image1:hover {
    transform: translateY(-5px);
}

.testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image2 {
    left: -42px;
    top: 32px;
    transition: all 0.3s ease-in-out;
}

.testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image2:hover {
    transform: translateY(-5px);
}

.testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image3 {
    left: 20px;
    bottom: -100px;
    transition: all 0.3s ease-in-out;
}

.testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image3:hover {
    transform: translateY(-5px);
}

.testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image4 {
    right: -58px;
    bottom: 10px;
    transition: all 0.3s ease-in-out;
}

.testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image4:hover {
    transform: translateY(-5px);
}

.testimonial-section .testimonial_contentwrapper {
    padding-left: 40px;
    height: 460px;
}

.testimonial-section .heading {
    margin-bottom: 15px;
    font-family: "roboto";
}

.testimonial-section .heading h6{
    font-family: "roboto";
    color: #ef8b33;
}


.testimonial-section .heading h2{
    font-weight: 700;
    font-family: "roboto";
    text-transform: uppercase;
    color: black;
    font-size: 40px;
    line-height: 54px;
    font-family: "roboto";
}

.testimonial-section .testimonial_content .content {
    display: flex;
}

.testimonial-section .testimonial_content .content .circle {
    background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
    border-radius: 100px;
    height: 64px;
    width: 64px;
    line-height: 64px;
    text-align: center;
    display: inline-block;
    border: 1px solid transparent;
    position: relative;
    margin-right: 16px;
    top: -4px;
    transition: all 0.3s ease-in-out;
}

.testimonial-section .testimonial_content .content .circle:hover {
    background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
    transform: translateY(-5px);
}

.testimonial-section .testimonial_content p {
    margin-bottom: 26px;
    margin-right: 26px;
    color:#656565;
    /* font-family: "roboto"; */

}

.testimonial-section .testimonial_content .content span {
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    color: black;
    margin-bottom: -2px;
    display: block;
    font-family: "roboto";
}

#carouselId #carouselExampleControls {
    display: inline-block;
    border-radius: 10px;
    color:#656565 !important;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
}

 .speak-carousel {
    position: relative;
    top: 0;
    height: 220px;
}

.testimonial-section .testimonial_content .content .circle img {
    width: auto;
    height:auto;
    position: relative;
    top: 0px;
    left: 0px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}
.speak-carousel .speak-item .content .circle img{

    position: relative;
    top: 0px;
    left: 0px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}



#carouselExampleControls .carousel-control-prev {
    left: 0;
    top: 0;
    font-size: 26px;
    line-height: 26px;
    color: #010521;
    opacity: 1;
    text-decoration: none;
}

#carouselExampleControls .carousel-control-prev i {
    transition: all 0.3s ease-in-out;
}

#carouselExampleControls .carousel-control-next {
    right: -58px;
    top: 0;
    font-size: 30px;
    line-height: 30px;
    color:#ef8b33 ;
    opacity: 1;
    text-decoration: none;

}

#carouselExampleControls .carousel-control-next i {
    transition: all 0.3s ease-in-out;
}

.testimonial-section .pagination-outer {
    position: relative;
    display: inline-block;
    bottom: 20px;
    right: -16px;
}


@media (min-width: 768px) and (max-width: 1280px){
    .testimonial-section {
        padding: 89px 0 85px;
    }
    .testimonial-section .service-leftlayer {
        top: -178px;
    }
    .testimonial-section .service-leftlayer img {
        width: 150px;
    }
    .testimonial-section .service-rightlayer {
        bottom: -178px;
    }
    .testimonial-section .service-rightlayer img {
        width: 150px;
    }
    .testimonial-section .testimonial_wrapper .service-rightcircle {
        top: 60px;
        left: 50px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper {
        height: 330px;
        width: 330px;
        margin: 0 auto 75px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .center-circle {
        height: 110px;
        width: 110px;
        left: -2px;
        top: 108px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .center-circle .testimonial-centerimage {
        top: 4px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image1 img {
        width: 200px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image2 img {
        width: 150px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image3 img {
        width: 170px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image4 img {
        width: 150px;
    }
    .testimonial-section .testimonial_contentwrapper {
        padding-left: 0;
        text-align: center;
        margin-top: 50px;
    }
    .testimonial-section .testimonial_contentwrapper h6 {
        margin-bottom: 2px;
    }
    .testimonial-section .testimonial_contentwrapper h2 {
        margin-bottom: -6px;
        padding: 0 150px;
        text-align: center;
    }
    .testimonial-section .testimonial_content p {
        margin-bottom: 18px;
        padding: 0 20px;
        text-align: center;
        font-family: "roboto";
        margin-right: 0px;
    }
    .testimonial-section .testimonial_content .content {
        display: block;
    }

    .testimonial-section .testimonial_content .content .designation-outer p{
        text-align: center;
        position: relative;
        left: 15px;
        
    }
    .testimonial-section .testimonial_content .content .circle {
        height: 50px;
        width: 50px;
        line-height: 50px;
        margin-right: 0;
        margin-bottom: 8px;
    }
    .testimonial-section .testimonial_content .content .circle img {
        width: 25px;
    }

    .testimonial-section .testimonial_content .content-box  {
        height: 230px;
    }

    .testimonial-section .testimonial_content .content-box .content {
        height: 120px;
    }
    .testimonial-section .testimonial_content .content span {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 0px;
        margin-left: 10px;
        font-family: "roboto";
    }
    .testimonial-section .pagination-outer {
        top: 12px;
        right: 60px;
    }
}

@media (max-width: 768px){
    .testimonial-section {
        padding: 75px 0 68px;
        height: 980px;
    }
    .testimonial-section .service-leftlayer {
        top: -152px;
    }
    .testimonial-section .service-leftlayer img {
        width: 125px;
    }
    .testimonial-section .service-rightlayer {
        bottom: -150px;
    }
    .testimonial-section .service-rightlayer img {
        width: 125px;
    }
    .testimonial-section .testimonial_wrapper .service-rightcircle {
        top: 70px;
        left: 0;
    }
    .testimonial-section .testimonial_wrapper .service-rightcircle img {
        width: 35px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper {
        height: 310px;
        width: 310px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .center-circle {
        height: 100px;
        width: 100px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .center-circle .testimonial-centerimage {
        top: 2px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image1 img {
        width: 190px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image2 img {
        width: 140px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image4 img {
        width: 140px;
    }
    .testimonial-section .testimonial_wrapper .circle_wrapper .testimonial-image3 img {
        width: 160px;
    }
    .testimonial-section .testimonial_contentwrapper {
        margin-top: 100px;
    }
    .testimonial-section .testimonial_contentwrapper h2 {
        padding: 0 0px;

    }
    .testimonial-section .testimonial_content p {
        padding: 0 12px;
    }

    .testimonial-section .testimonial_content .content-box  {
        height: 280px;
    }
    .testimonial-section .testimonial_content .content-box  p{
        margin-right: 0px;
    }

    .testimonial-section .testimonial_content .content-box .content {
        height: 220px;
    }

    .testimonial-section .testimonial_content .content-box .content .designation-outer{
        height: 50px;
    }
    .testimonial-section .testimonial_content .content .circle {
        height: 40px;
        width: 40px;
        line-height: 40px;
    }
    .testimonial-section .testimonial_content .content .circle img {
        width: 20px;
    }
    #carouselExampleControls .carousel-control-prev {
        font-size: 18px;
        line-height: 18px;
    }
    #carouselExampleControls .carousel-control-next {
        right: -38px;
        font-size: 24px;
        line-height: 24px;
    }

    
    #carouselExampleControls .carousel-inner{
        height: 280px;
    }
    .testimonial-section .pagination-outer {
        top: 0px;
        right: 40px;
    }

    .testimonial-section .testimonial_content .content .designation-outer p{
        text-align: center;
        position: relative;
        left: 0px;
        
    }
    .testimonial-section .testimonial_content .content .designation-outer span{
        text-align: center;
        position: relative;
        left: 0px;
        
    }
}



.mobile-sol-sec{
  /* border: 2px solid red; */
  justify-content: space-around;
  /* margin-top: 70px; */
}
.mob-sol-sec2{
  margin-top: 110px;
}
.mobile-solution-data{
border: 1px ridge none;
transition: transform 0.8s ease;
}

.mobile-solution-image{
  padding: 30px;
  transition: transform 0.3s ease;
}

.mobile-solution-image img{
  width: 100%;
  height: 350px;
  /* padding-top: 30px; */
  animation: slidebottom 1s forwards;
  object-fit: cover;  
}
/* .mobile-solution-data:hover{
box-shadow: 1px 1px 20px 1px rgba(97, 94, 250, 0.61);
} */
.mobile-solution-data:hover .mobile-solution-image{
transform: scale(1.1);
}

.mobile-solution-heading{
  font-family: 'Playfair Display';
  padding: 25px;
  text-align: center;
}

.mobile-solution-content-sec .test-para{
  font-size: 21px;
  padding-left: 20px;
  font-family: 'roboto';
  justify-content: center;

}
.mobile-solution-content-sec{
  padding-left:30px;
  padding-top: 40px;
  animation: slideRight 1s forwards;
  animation: slideOut 1s forwards;
    
}


@media (max-width: 768px){
  .mobile-solution-content-sec{
      padding-top: 0px;
  }
  .mobile-solution-data:hover .mobile-solution-image{
    transform: scale(1);
    }
}

@media (max-width: 992px){
  .mobile-solution-content-sec{
      padding-top: 0px;
  }
}

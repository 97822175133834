/* VideoLoader css start */
.video-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: white;
  /* background-repeat: no-repeat;
  background-size: cover; */
  width: 100vw;
  height: 100vh;
}

.loader-video {
  width: 150px;
  height: auto;
  animation: scaleUp 3s ease-in-out infinite;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
.multicolor-text {
  text-align: center;
  font-size: 50px;
  background: linear-gradient(to left, Navy, orange);
  -webkit-background-clip: text;
  color: transparent;
  margin-top: 10px;
}
body {
  overflow-x: hidden;
}

/* videoloader css end */

/* cursor css start */
.custom-cursor {
  cursor: none;
}

.custom-cursor__cursor {
  position: fixed;
  width: 24px;
  height: 24px;
  /* background-color: #fcefef; */
  border: 2px solid rgb(240, 133, 48);
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.1s ease;
  z-index: 9999;
}

.custom-cursor__cursor-two {
  position: fixed;
  width: 10px;
  height: 10px;
  background-color: #0a37c9; 
  border-radius: 50%;
  pointer-events: none;
  transition: left 0.1s ease, top 0.1s ease;
  z-index: 10000;
}

/* Style changes on mouse click */
.custom-cursor__cursor.click {
  transform: scale(1.2);
}

.custom-cursor__cursor-two.custom-cursor__innerhover {
  background-color: #d41a1a; /* Change color on click */
}

/* cursor css end */

/* Topbar css  star*/
.block {
  background: rgb(14, 18, 42);
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  top: 0;
  z-index: 1000;
}
/* Add this CSS */
.top-bar-hidden .header-area {
  top: 0;
}
.top-bar-hidden .top-bar {
  display: none;
}
.top-bar {
  transition: top -99.99s;
}

.block .top-address ul li {
  display: inline-block;
  font-size: 15px;
  font-style: bold;
  padding-right: 25px;
  position: relative;
  right: 20px;
  top: -6px;
  color: #d9d9d9;
}
.block .top-address ul li:hover {
  color: rgb(240, 133, 48);
  text-decoration: underline;
}
.block .top-address ul li i {
  border-radius: 50%;
  color: black;
  padding: 10px;
  /* color: #fada6f; */
  font-size: 16px;
  margin: 17px;
  background-color: rgb(240, 133, 48);
}

.block .top-address ul li:hover i {
  background-color: rgb(253, 251, 240);
  border: 1px solid rgb(240, 133, 48);
  border-radius: 50%;
  color: rgb(240, 133, 48);
  animation: scaleUp 1s forwards;
}
@keyframes scaleUp {
  from {
    transform: scale(-0.1);
    opacity: 0;
  }
  to {
    transform: scale(1.4);
    opacity: 1;
  }
}

.block .top-social-link ul {
  display: flex;
}
.block .top-social-link ul li {
  display: flex;
  padding-right: 18px;
  position: relative;
  bottom: 7px;
}

@media (max-width: 921px) {
  .block {
    visibility: hidden;
  }
}
/* Laptops and Desktops (992px and above) */
@media only screen and (min-width: 992px) {
  .top-bar {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .top-address ul,
  .top-social-link ul {
    display: flex;
    flex-direction: row;
    margin: 0;
  }
  .block .top-address ul li i {
    margin: 0;
    position: relative;
    left: -6px;
  }
  .top-address li,
  .top-social-link li {
    margin-right: 2 0px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .top-bar {
    height: 60px;
  }
  .block .top-address ul li {
    font-size: 11px;
  }
  .top-address ul,
  .top-social-link ul {
    position: relative;
    top: 4px;
  }
  .block .top-address ul li i {
    margin: 0;
    position: relative;
    margin-left: 10px;
    left: -9px;
  }
}

/* Topbar css end */

/* Logo css */
.logo-area {
  position: relative;
  left: 40px;
}

.logo-area img {
  width: 130px;
  height: 110px;
  padding: 10px;
}
@media (max-width: 768px) {
  .logo-area img {
    position: relative;
    margin-top: -10px;
    margin-left: -50px;
    width: 110px;
  }
}
@media only screen and (min-width: 992px) {
  .logo-area img {
    position: relative;
    width: 110px;
    right: 35px;
    object-fit: cover;
  }
}
@media (min-width: 769px) and (max-width: 880px) {
  .logo-area img {
    position: relative;
    top: 0;
    width: 140px;
    height: 120px;
  }
}
/* @media (min-width: 769px) and (max-width: 1024px) {
  .logo-area img {
    position: relative;
    top: 0;
    height: 90px;
  }
} */
@media (min-width: 1025px) and (max-width: 1280px) {
  .logo-area img {
    position: relative;
    top: 0;
  }
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .logo-area img {
    position: relative;
    top: 0px;
    left: 60px;
  }
}
/* Logo css end */

/*     Navbar css           */
.header-area {
  /* background: rgb(254, 226, 202); */
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 236, 220, 1) 80%
  );
  height: 87px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 1000;
  transition: top 0s;
}
.main-menu-area {
  /* background: rgb(254, 226, 202); */
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 236, 220, 1) 80%
  );
}

.main-menu-area > ul > li {
  display: inline-block;
  position: relative;
  font-family: "Roboto", sans-serif;
  cursor: pointer;

  /* left: 80px; */
}
.main-menu-area > ul > li > span {
  /* color: #222222; */
  color: rgb(14, 18, 42);
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}
.main-menu-area > ul > li > span:hover {
  color: rgb(248, 99, 46);
}
@media (max-width: 768px) {
  .header-area {
    top: 0px;
  }

  .header-area .navbar-toggler {
    position: relative;
    margin-bottom: 10px;
  }
  .navbar-collapse {
    position: relative;
    /* top: -40px; */
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .header-area {
    top: 60px;
    height: 75px;
  }
  .navbar-nav {
    position: relative;
    left: 0px;
    font-size: 10px;
  }
}
@media (min-width: 769px) and (max-width: 880px) {
  .header-area{
    top: 0;
    height: 105px;
  }
  .header-area .navbar-toggler {
    margin-bottom: 10px;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .header-area{
    /* top: 0; */
    height: 79px;
  }
}
.nav-link {
  position: relative;
  margin: 1.2rem;
  padding-bottom: 0.5rem;
  color: rgb(14, 18, 42);
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}
.nav-link::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 3px;
  background: black;
  border-radius: 5px;
  transform: scaleX(0);
  transition: all 0.6s ease;
  bottom: 0;
  left: 0;
}

.nav-link.active::after {
  transform: scaleX(1);
}

.nav-link:hover::after {
  transform: scaleX(1);
}

/* Dropdown menu styles */
.main-menu-area > ul > li:hover ul.rt-dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.main-menu-area > ul > li ul.rt-dropdown-menu {
  background: #1d285b;
  box-shadow: none;
  border: none;
  border-radius: 0;
  opacity: 0;
  margin: 0;
  min-width: 240px;
  padding: 0;
  top: 100%;
  position: absolute;
  visibility: hidden;
  transform: translateY(100px);
  transition: all 0.42s ease-out;
  z-index: 9999;
}
.main-menu-area > ul > li ul.rt-dropdown-menu li {
  display: block;
  border-bottom: 1px solid #293673;
  position: relative;
}
.main-menu-area > ul > li ul.rt-dropdown-menu li span {
  display: block;
  color: #ffffff;
  font-weight: 400;
  padding: 12px 15px;
  text-transform: capitalize;
  transition: all 0.3s ease-out;
  text-decoration: none;
}
.main-menu-area > ul > li ul.rt-dropdown-menu li span:hover,
.main-menu-area > ul > li ul.rt-dropdown-menu li span:focus {
  background: #293673;
  padding-left: 18px;
}
/* Dropdown menu styles end*/

/* Navbar css end */

/* slider css start */
.carousel-inner {
  position: relative;
  width: 100%;
  height: 520px;
  top: 100px;
  background-image: url("../public/img/Artboard3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-inner .carousel-item {
  position: relative;
  /* display: none; */
  /* float: left; */
  width: 100%;
  /* margin-right: -100%; */
  backface-visibility: hidden;
  transition: transform 6s ease !important;
}
.carousel-inner .carousel-item img {
  width: 742px;
  height: 472px;
  position: relative;
  top: 28px;
  left: 540px;
  z-index: 1;
  border-top-left-radius: 150px;
  border-bottom-right-radius: 150px;
}
.carousel-inner .carousel-item .carousel-caption {
  width: 380px;
  position: relative;
  left: 18px;
  bottom: 390px;
}
.carousel-inner .carousel-item .carousel-caption .carousel-content {
  text-align: left;
}

.carousel-inner
  .carousel-item
  .carousel-caption
  .carousel-content
  .carousel-content-btn1 {
  cursor: pointer;
  position: relative;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  border: 2px solid rgb(240, 133, 48);
  border-radius: 34px;
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 1) 0%,
    rgba(239, 139, 51, 1) 100%
  );
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
}
.carousel-inner
  .carousel-item
  .carousel-caption
  .carousel-content
  .carousel-content-btn1::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 80px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: rgb(53, 57, 86);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.carousel-inner
  .carousel-item
  .carousel-caption
  .carousel-content
  .carousel-content-btn1:hover::before {
  scale: 3;
}
.carousel-inner
  .carousel-item
  .carousel-caption
  .carousel-content
  .carousel-content-btn1:hover {
  border: 2px solid rgb(53, 57, 86);
  color: #ffffff;
  scale: 1.1;
  box-shadow: 0 0px 20px rgba(193, 163, 98, 0.4);
}
.carousel-inner
  .carousel-item
  .carousel-caption
  .carousel-content
  .carousel-content-btn1:active {
  scale: 1;
}

/* Media query for screens smaller than 768px (typical mobile devices) */
@media (max-width: 767.98px) {
  .carousel-inner {
    top: 35px;
    /* object-fit: cover; */
    min-height: 800px;
    /* background-repeat: no-repeat; */
    /* background-image: url("../public/img/Artboard3.jpg"); */
    background-size: cover;
    /* width: 100%; */
  }
  .carousel-inner .carousel-item img {
    position: relative;
    top: 300px;
    left: 0;
    object-fit: cover;
    width: 330px;
    margin-left: 15px;
  }
  .carousel-inner .carousel-item .carousel-caption {
    /* border: 2px solid green; */
    margin-top: -50px;
    margin-left: -50px;
  }
  .carousel-inner .carousel-item h1 {
    /* margin-top: -50px; */
    font-size: 37px !important;
  }

  .carousel-inner .carousel-item p {
    font-size: 16px !important;
  }

  .carousel-inner
    .carousel-item
    .carousel-caption
    .carousel-content
    .carousel-content-btn1 {
    width: 150px;
    height: 50px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) {
  .carousel-inner {
    top: 35px;
    object-fit: cover;
    height: 585px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
  .carousel-inner .carousel-item img {
    position: relative;
    top: 90px;
    width: 55%;
    left: 40%;
    object-fit: cover;
  }

  .carousel-inner .carousel-item h1 {
    font-size: 40px !important;
    position: relative;
    top: 150px;
  }

  .carousel-inner .carousel-item p {
    font-size: 19px !important;
    position: relative;
    top: 150px;
  }
  .carousel-inner
    .carousel-item
    .carousel-caption
    .carousel-content
    .carousel-content-btn1 {
    position: relative;
    top: 150px;
  }
}

@media only screen and (max-width: 1278px) {
  .carousel-inner .carousel-item .carousel-caption .carousel-content {
    position: relative;
    left: 40px;
  }
}
@media (min-width:769px) and (max-width:880px) {
  .carousel-inner {
    top: 90px;
  }
  .carousel-inner .carousel-item img {
    position: relative;
    top: 25px;
    width: 55%;
    left: 43%;
    object-fit: cover;
  }
  .carousel-inner .carousel-item .carousel-caption {
    /* border: 2px solid green; */
    margin-top: 50px;
    margin-left: -35px;
  }
  .carousel-inner .carousel-item h1 {
    font-size: 32px !important;
  }

  .carousel-inner .carousel-item p {
    font-size: 27px !important;
  }

  .carousel-inner
    .carousel-item
    .carousel-caption
    .carousel-content
    .carousel-content-btn1 {
    font-size: 17px;
  }
}

@media (min-width:882px) and (max-width:1280px) {
  .carousel-inner {
    top: 90px;
  }
  .carousel-inner .carousel-item img {
    position: relative;
    top: 75px;
    width: 55%;
    left: 43%;
    object-fit: cover;
  }
  .carousel-inner .carousel-item .carousel-caption {
    /* border: 2px solid green; */
    margin-top: -40px;
    margin-left: 35px;
  }
  .carousel-inner .carousel-item h1 {
    font-size: 32px !important;
  }

  .carousel-inner .carousel-item p {
    font-size: 27px !important;
  }

  .carousel-inner
    .carousel-item
    .carousel-caption
    .carousel-content
    .carousel-content-btn1 {
    font-size: 17px;
  }
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .carousel-inner .carousel-item .carousel-caption .carousel-content {
    position: relative;
    width: 100%;
    left: 100px;
  }
}
/* animation css start */

/* Define the fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(10);
  }
}

/* Apply the animation to elements with the fadeInUp class */
.fadeInUp {
  animation: fadeInUp 1.3s ease-in-out;
}

/* Define the fadeInDown animation */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to elements with the fadeInDown class */
.fadeInDown {
  animation: fadeInDown 1.9s ease-in-out;
}

/* Define the fadeInLeft animation */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Apply the animation to elements with the fadeInLeft class */
.fadeInLeft {
  animation: fadeInLeft 1.2s ease-in-out;
}

/* animation css end */

/* slider css End  */

/* OurNumber css start */
.ournumber-top {
  position: relative;
  top: 100px;
}
.counter-area1 {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 110px 0 90px;
  background-position: center;
  position: relative;
  bottom: 30px;
}

.counter-area1::after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 437px;
  z-index: 1;
}

.counter-area1 .number-head {
  position: relative;
  z-index: 2;
}

.counter-area1 .counter-title h2 {
  font-size: 46px;
  color: #ffffff;
  margin-bottom: 80px;
  text-align: center;
}

.counter-area1 .counter-content {
  text-align: center;
  padding: 0;
}

.counter-area1 .counter-content .counter1-box {
  position: relative;
  padding-left: 10px;
  display: inline-block;
}

.counter-area1 .counter-content span {
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 5px;
}

.counter-area1 .counter-content p {
  color: #fff;
  font-size: 27px;
  margin-bottom: 0;
  font-weight: bold;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .counter-area1 {
    padding: 80px 0 60px;
    bottom: 0;
    height: 550px;
    width: 100%;
  }

  .counter-area1::after {
    height: 550px;
  }
  .counter1-box {
    position: relative;
    padding-right: 60px;
    margin-top: 20px;
    margin-left: 50px;
  }
  .counter-area1 .counter-title h2 {
    font-size: 36px;
    margin-bottom: 60px;
  }

  .counter-area1 .counter-content span {
    font-size: 32px;
  }

  .counter-area1 .counter-content p {
    font-size: 20px;
  }
}

/* OurNumber css end */

/* client-speack css start */
.client-speack-top {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
  border: 2px solid none;
}

.clients-speak-section {
  border: 2px solid violet none;
  display: flex;
  justify-content: space-between;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.left-section {
  border: 2px solid red none;
  /* width: 50%; */
  text-align: left;
  padding: 20px;
}

.left-section h2 {
  font-size: 40px;
  font-family: "Times New Roman", Times, serif;
  margin-bottom: 20px;
}

.left-section p {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: justify;
  padding-right: 35px;
}

.left-section button {
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 1) 0%,
    rgba(239, 139, 51, 1) 100%
  );
  color: white;
  border: none;
  border-radius: 35px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 20px;
}
.left-section button:hover {
  background: rgb(53, 57, 86);
}
.right-section {
  border: 2px solid green none;
  /* width: 50%; */
}

.testimonial {
  border: 1px solid orange;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 0 15px; 
  max-width: 500px;
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.testimonial p {
  font-size: 20.7px;
  margin-bottom: 20px;
  text-align: left;
}

.client-info {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.client-info img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  object-fit: cover;
}

.client-info h4 {
  margin: 0;
  font-size: 1rem;
}

.client-info p {
  margin: 0;
  font-size: 0.875rem;
  color: #888;
}

/* Mobile Styles */
@media only screen and (max-width: 767px) {
  .client-speack-top {
    width: 100%;
    overflow-x: hidden;
    margin-top: 90px;
    font-size: 16px; /* Adjust text size if needed */
  }

  .clients-speak-section {
    flex-direction: column;
    padding: 20px;
  }

  .left-section,
  .right-section {
    border: 2px solid transparent; /* Remove or adjust borders for smaller screens */
    width: 100%;
    text-align: center;
  }

  .left-section h2 {
    font-size: 24px; /* Smaller font size for mobile */
    margin-bottom: 15px;
  }

  .left-section p {
    font-size: 0.875rem; /* Adjust font size */
    margin-bottom: 15px;
  }

  .left-section button {
    font-size: 16px; /* Adjust button size */
    padding: 8px 16px;
  }

  .right-section {
    /* If there are specific styles for right-section on mobile, apply them here */
    margin-top: 20px; /* Add some margin if needed */
  }

  .testimonial {
    width: 100%; /* Make testimonials full width on mobile */
    height: auto; /* Adjust height to auto to fit content */
    margin: 0px; /* Add spacing between testimonials */
  }

  .testimonial p {
    font-size: 18px; /* Adjust text size for better readability */
  }

  .client-info {
    flex-direction: column;
    text-align: center;
    margin-bottom: 10px; /* Adjust margin if needed */
  }

  .client-info img {
    margin-right: 0;
    margin-bottom: 10px;
    object-fit: cover;
  }

  .client-info h4 {
    font-size: 0.875rem; /* Adjust font size */
  }

  .client-info p {
    font-size: 0.75rem; /* Adjust font size */
  }
}

@media (min-width: 770px) and (max-width: 1024px) {
  .testimonial {
    max-width: 95%; /* Make testimonials full width on mobile */
    height: 480px; /* Adjust height to auto to fit content */
    margin: 0px; /* Add spacing between testimonials */
  }
}

/* client-speack css end */

/* clients css start */
.client-title {
  border: 2px solid violet none;
  position: relative;
  padding: 60px 0px 0px 0px;
}
.client-title h1 {
  padding-bottom: 20px;
  position: relative;
  text-align: center;
  font-size: 38px;
  font-family: "Playfair Display", serif !important;
  line-height: 38px;
}
.client-title h1 {
  text-align: center;
  font-size: 50px;
  font-family: "roboto";
  position: relative;
  top: 0px;
}
.icon {
  position: relative;
  overflow: hidden;
  height: 210px;
  border: 2px solid red none;
}

.icon:before,
.icon:after {
  content: "";
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 15rem;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1),
    transparent
  );
}

.icon:after {
  left: auto;
  right: 0;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.1),
    transparent
  );
}
.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  border: 1px solid rgb(241, 238, 238);
}
.marquee.hover-pause:hover .marquee-content {
  animation-play-state: paused;
}

.marquee-content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  animation: scroll 15s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee-content {
    animation-play-state: paused !important;
  }
}

.marquee-content li {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  border: 1px solid blue;
  border-radius: 10px;
  padding: 20px;
  margin: 30px;
}
.marquee-content li:hover {
  background-color: rgba(255, 166, 0, 0.322);
  border: 1px solid rgba(255, 166, 0, 0.322);
}
.marquee-content img {
  max-width: 100px;
}

@keyframes scroll {
  0% {
    transform: translatex(10%);
  }

  100% {
    transform: translatex(-100%);
  }
}

/* clients css end */

/* footer css start */
.main10 {
  /* border: 4px solid orange; */
  position: relative;
  top: 140px;
  /* height: 230px; */
}
.fix1 {
  /* border: 3px solid rgb(79, 13, 133); */
  position: relative;
  height: 230px;
}
.footer_heading {
  position: relative;
  left: 30px;
  color: black;
  font-weight: 650;
}
.footer_listblock {
  list-style: none;
  position: relative;
  top: 5px;
}
.footer_list a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 800px;
  font-family: "Mulish", sans-serif !important;
}
.footer_list a:hover {
  color: orange;
}
.heading {
  color: black;
  font-weight: 650;
}
.add {
  font-size: 17px;
  font-family: system-ui;
  position: relative;
}
.contact-us a {
  text-decoration: none;
  color: white;
  background-color: rgb(240, 133, 48);
  font-family: "Arial";
  font-style: "bold";
  font-size: 18px;
  padding: 5px 13px;
  box-shadow: 1px 2px 7px grey;
  border: 1px solid rgb(235, 230, 230);
}
.contact-us a:hover {
  border: 1px solid rgb(235, 230, 230);
  box-shadow: 1px 5px 14px gray;
  border-radius: 10% 50% 10% 50% /50% 10% 50% 10%;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .main10 {
    top: 120px;
    margin: 0;
    padding: 0;
  }
  .footer_heading,
  .contact-us a {
    margin-bottom: 10px;
    font-size: 25px;
  }
  .head-top {
    border: 4px solid green;
  }
  .heading {
    margin-bottom: 10px;
    font-size: 25px;
    position: relative;
    left: 25px;
  }
  .add {
    margin-bottom: 10px;
    font-size: 22px;
    position: relative;
    left: 30px;
  }
  .fix1 {
    height: auto;
  }

  .footer_list {
    font-size: 21px;
  }
  .contact-us {
    position: relative;
    left: 20px;
  }
}
/* footer css end */

/* footer2 css start */
.fix10 {
  /* border: 3px solid #1d285b; */
  margin-top: 100px;
}
.fix2 {
  border: 2px solid black none;
}
.d-flex {
  position: relative;
  bottom: 10px;
  justify-content: center;
  padding-right: 60px;
  top: 0px;
}
.d-flex img {
  height: 80px;
  position: relative;
  left: 40px;
}

.img-contain {
  width: 100%;
  transition: transform 0.4s ease;
}
.d-flex:hover .img-contain {
  transform: scale(1.3);
}
.footer_location_info h5 {
  position: relative;
  font-weight: bold;
  font-size: 30px;
  font-family: "Playfair Display" !important;
  padding-left: 65px;
}
.usa-info {
  position: relative;
  top: 23px;
}
.location_name {
  position: relative;
  font-size: 18px;
  padding-left: 65px;
}

.footer1_listblock {
  list-style: none;
  /* border: 2px solid; */
}
.footer1_listblock li {
  display: inline-block;
  position: relative;
  right: 17px;
}
.footer1_list a {
  list-style: none;
  text-decoration: none;
  font-family: "Mulish", sans-serif !important;
  line-height: normal;
  color: black;
  font-size: 16px;
}
.footer1_list a:hover {
  text-shadow: 1px 1px rgb(245, 181, 62);
  color: orange;
}
.footer-privacy {
  /* border: 3px solid rgb(151, 17, 17); */
  height: 37px;
  top: 60px;
  margin-bottom: 0px;
}
.footer-sun {
  color: green;
  font-weight: 700;
}
.footer-privacy .footer2_listblock {
  height: 35px;
  width: 290px;
}
.footer-privacy .footer2_listblock li {
  display: inline-block;
  margin-right: 20px;
  height: 35px;
}
.footer-privacy .footer1_list {
  right: 50px;
}
.border_line {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .fix2 {
    border: none;
  }
  .fix10 {
    position: relative;
    top: 80px;
  }

  .d-flex {
    height: auto;
  }
  .fix2 {
    margin-left: 30px;
  }
  .d-flex img {
    height: 70px;
    left: 0;
    margin-bottom: 10px;
  }
  .usa-data {
    position: relative;
    margin-left: 25px;
  }
  .usa-info {
    position: relative;
    left: 20px;
  }
  .footer_location_info h5,
  .location_name {
    padding-left: 0;
    text-align: center;
  }
  .footer1_listblock li {
    font-size: 19px;
  }
  .footer1_list a {
    text-align: center;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 19px;
  }
  .footer-icon .footer2_listblock {
    margin-top: 10px;
    position: relative;
    right: 30px;
  }
  .footer-sun {
    color: green;
    font-weight: 700;
  }
}

@media (min-width: 770px) and (max-width: 1280px) {
  .fix10 {
    position: relative;
    top: 180px;
  }
}

/* footer2 css end */

/* privacy css start */
.privacy-top {
  /* border: 3px solid green; */
  position: relative;
  top: 124px;
  margin-bottom: 200px;
}
.box27 {
  color: black;
  font-size: 18px;
}
.box27 h6 {
  font-size: 22px;
}
.box27 li {
  font-size: 18px;
  font-weight: 600;
}
.box25 {
  text-align: center;
  color: black;
  font-family: "Roboto";
}
.box26 {
  text-align: center;
}
@media (min-width:769px) and (max-width:1289px) {
  .privacy-top {
    top: 90px;
  }
}
/* privacy css end */

/* our-number2 css start */
.toplayer {
  position: absolute;
  top: -120px;
  right: 240px;
  z-index: -1;
}
.bottomlayer {
  position: absolute;
  top: 230px;
  left: 220px;
  z-index: -1;
}
.achievement-section {
  overflow: hidden;
  position: relative;
  padding: 80px 0 20px;
}
.achievement-section .achievement_wrapper {
  position: relative;
}
.achievement-section .achievement_content h2 {
  margin-top: 55px;
}
.achievement-section .achievement_content p {
  padding: 10px 0px;
  font-size: 28px;
}
.achievement-section .achievement_wrapper .achievement-box {
  background: var(--e-global-color-white);
  border-radius: 100px;
  width: 210px;
  height: 210px;
  line-height: 210px;
  display: inline-block;
  text-align: center;
  transition: all 0.3s ease-in-out;
  box-shadow: 1px 21px 46px rgb(0 0 0 / 2%);
  position: absolute;
}

.achievement-section .achievement_wrapper .achievement-box:hover {
  background-image: linear-gradient(
    to right,
    var(--e-button-gradient-1) 0%,
    var(--e-button-gradient-2) 100%
  );
  transform: translateY(-5px);
}
.achievement-section .achievement_wrapper .achievement-box:hover img {
  filter: brightness(0) invert(1);
}

.achievement-section .achievement_wrapper .achievement-box .wrapper {
  position: absolute;
  bottom: 26px;
  left: 2px;
  right: 0;
  margin: 0 auto;
}
.achievement-section .achievement_wrapper .achievement-box .pride-data {
  bottom: 10px;
}

.achievement-section .achievement_wrapper .achievement-box p {
  padding: 0 65px;
  margin-top: -8px;
  line-height: 25px;
  font-size: 20px;
}

.achievement-section .achievement_wrapper .achievement-box .number {
  position: relative;
  top: 79px;
}

.achievement-section .achievement_wrapper .achievement-box .plus {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}

.achievement-section .achievement_wrapper .achievement-box:hover .plus {
  color: var(--e-global-color-white);
}

.achievement-section .achievement_wrapper .box1 {
  top: 58px;
  left: 22px;
}

.achievement-section .achievement_wrapper .box2 {
  top: -42px;
  left: 226px;
}

.achievement-section .achievement_wrapper .box3 {
  top: 58px;
  right: 0;
  bottom: 50px;
}

.counter {
  font-size: 30px;
  margin-bottom: 20px;
}
.achievement-icon {
  border-radius: 50%;
}
.achievement-icon1 {
  border-radius: 50%;
}
.achievement-icon img {
  position: relative;
  bottom: 40px;
  width: 90px;
  height: 80px;
}
.achievement-icon1 img {
  position: relative;
  bottom: 50px;
  width: 50px;
  height: 50px;
}
.achievement-icon:hover {
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 1) 0%,
    rgba(239, 139, 51, 1) 100%
  );
}
.achievement-icon1:hover {
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 1) 0%,
    rgba(239, 139, 51, 1) 100%
  );
}
.achievement-section .achievement_wrapper .achievement-box:hover .text {
  color: #fff;
}
.achievement-section .achievement_wrapper .achievement-box:hover .plus {
  color: #fff;
}
.achievement-section .achievement_wrapper .achievement-box:hover .counter {
  color: #fff;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .toplayer {
    position: absolute;
    top: 0px;
    right: 0;
    /* height: 500px; */
    width: 500px;
    z-index: -1;
  }
  .bottomlayer {
    position: absolute;
    top: 970px;
    left: 0px;
    z-index: -1;
  }
  .achievement-section {
    padding: 30px 0 120px;
    min-height: 1100px;
  }

  .achievement_content h2 {
    font-size: 1.5rem;
  }

  .achievement-section .achievement_wrapper .box1 {
    top: 10px;
    left: 60px;
  }
  .achievement-section .achievement_wrapper .box1 span {
    position: relative;
  
    top: 5px;
  }
  .achievement-section .achievement_wrapper .box1 .plus {
    position: relative;
    top: 10px;
  }
  .achievement-section .achievement_wrapper .box2 {
    top: 250px;
    left: 60px;
  }
  .achievement-section .achievement_wrapper .box3 {
    top: 490px;
    left: 60px;
    bottom: 0;
  }
  .number {
    font-size: 1.5rem;
  }

  .plus {
    font-size: 1.5rem;
  }

  .text {
    font-size: 0.875rem;
  }
}

/* Tablet view adjustments */
@media (min-width: 769px) and (max-width: 880px) {
  .toplayer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 500px;
    z-index: -1;
  }
  .bottomlayer {
    position: absolute;
    top: 300px;
    left: 20px;
    z-index: -1;
  }
  .achievement-section {
    padding: 15px;
    min-height: 560px;
  }

  .achievement_content h2 {
    font-size: 1.75rem;
  }

  .achievement-section .achievement_wrapper {
    margin: 40px;
  }

  .number {
    font-size: 1.75rem;
  }

  .plus {
    font-size: 1.75rem;
  }

  .text {
    font-size: 1rem;
  }
}



@media (min-width: 1367px) and (max-width: 1920px) {
  .achievement-section {
    padding: 90px 0 70px;
  }
  .bottomlayer {
    top: 230px;
    left:400px;
  }
  /* .achievement-section .achievement_content h2 {
    margin-top: 50px;
    left: -120px;
  } */
  .achievement-section .achievement_wrapper .box1 {
    left: 10%;
    top: 55px;
  
  }
  .achievement-section .achievement_wrapper .box2 {
    margin-left: 105px;

  }
  .achievement-section .achievement_wrapper .box3 {
    left: 79%;
    top: 57px;
  }
}
/* our-number2 css end */

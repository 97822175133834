.data-entry-sec{
    /* border: 2px solid red; */
    justify-content: space-around;
    /* margin-top: 70px; */
}
.data-entry-top{
    margin-top: 140px;
}
.data-entry-data{
  border: 1px ridge none;
  transition: transform 0.8s ease;
}
.data-para{
    text-align: center;
    font-size: 21px;
    font-family: 'roboto';
    font-style: bold;
}
.data-entry-image{
    padding: 30px;
    transition: transform 0.3s ease;
}

.data-entry-image img{
    width: 100%;
    height: 350px;
    /* padding-top: 30px; */
    animation: slidebottom 1s forwards;
object-fit: cover;  
}
.data-entry-data:hover{
    box-shadow: 1px 1px 20px 1px rgba(97, 94, 250, 0.61);
}
.data-entry-data:hover .data-entry-image{
  transform: scale(1.1);
}

.data-entry-heading{
    font-family: 'Playfair Display';
    padding: 25px;
    text-align: center;
}

.data-entry-content .data-entry-para{
    font-size: 21px;
    padding-left: 20px;
    font-family: 'roboto';
    justify-content: center;

}
.data-entry-content{
    padding-left:30px;
    padding-top: 40px;
    animation: slideRight 1s forwards;
    animation: slideOut 1s forwards;
      
}


@media (max-width: 768px){
    .data-entry-content{
        padding-top: 0px;
    }
    .data-entry-data:hover .data-entry-image{
        transform: scale(1);
      }
}

@media (max-width: 992px){
    .data-entry-content{
        padding-top: 0px;
    }
}
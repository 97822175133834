.our-heading {
  font-family: "roboto";
  text-decoration: underline;
  padding: 25px;
}
.products-back {
  padding: 0px;
}
.product-data-top {
  margin-top: 20px;
}
.productback-image img {
  /* width: 100%;
    height: 450px; */
  animation: scaleUp 1s forwards;
  object-fit: cover;
}
.productback-image {
  padding: 0%;
}
.product-head1 {
  position: relative;
  font-family: "playfair Display";
  text-align: center;
  font-size: 40px;
  padding: 20px;
  animation: scaleUp 1s forwards;
}

/* Make sure the main-img container is positioned relative */
.productback-image {
  position: relative;
}

/* Style the banner_content */
.product-banner {
  position: relative;
  bottom: 465px;
  left: 10%;
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 10;
}
.product-banner .product-box {
  position: relative;
  top: 10px;
  left: 430px;
  border: 2px solid rgb(53, 57, 86);
  width: 240px;
  height: 50px;
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
}
.product-banner .product-box span {
  position: relative;
  top: 5px;
  font-size: 20px;
}
.product-banner h1 {
  position: relative;
  right: 95px;
  font-size: 66px;
  line-height: 72px;
  font-weight: 700;
  text-transform: uppercase;
}
.product-banner p {
  position: relative;
  right: 95px;
  font-size: 20px;
  line-height: 30px;
}
.product-banner .product-box .box-home {
  color: white;
}
.product-banner .product-box .box_span {
  color: #ef8b33;
}
.product-banner .product-box .arrow {
  padding: 0px 20px;
}
.product-banner .product-box .arrow img {
  width: 30px;
}

/* Media Query for mobile view */
@media (min-width: 769px) and (max-width: 1280px) {
  /* .product-data-top {
    margin-top: -350px;
  } */
  .product-banner {
    position: relative;
    margin-top: 180px;
    left: 9%;
    padding: 15px;
    width: 80%;
  }

  .product-banner .product-box {
    position: relative;
    left: 190px;
    min-width: 190px;
    height: 40px;
    border: 2px solid rgb(53, 57, 86);
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }

  .product-banner .product-box span {
    font-size: 16px;
    position: relative;
    top: 6px;
  }

  .product-banner h1 {
    position: relative;
    right: 0;
    font-size: 48px;
    line-height: 54px;
  }

  .product-banner p {
    position: relative;
    right: 0;
    font-size: 16px;
    line-height: 24px;
  }

  .product-banner .product-box .arrow img {
    width: 25px;
  }
}

/* Additional styles for very small devices (portrait phones) */
@media (max-width: 768px) {
  .products-back {
    margin-top: 80px;
  }
  .product-data-top {
    margin-top: -430px;
  }
  .productback-image img {
    width: 100vw;
  }
  .product-banner {
    position: relative;
    margin-top: 72%;
    padding: 10px;
    width: 90%;
  }
  .product-banner .product-box {
    width: 180px;
    height: 35px;
    left: 40px;
  }

  .product-banner .product-box span {
    font-size: 14px;
    position: relative;
    top: 1px;
  }

  .product-banner h1 {
    font-size: 36px;
    line-height: 42px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }

  .product-banner p {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }

  .product-banner .product-box .arrow {
    width: 10px;
    height: 10px;
  }
}



.producttop-heading {
  font-family: "playfair Display";
  text-align: center;
  font-size: 40px;
  padding: 20px;
  animation: scaleUp 1s forwards;
}

@keyframes scaleUp {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* @keyframes slidebottom {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
} */

/* .product-complete{
  margin-top: 120px;
} */
.product-data-para {
  margin-top: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}

.product {
  /* border: 3px solid blue; */
  height: 600px;
  padding-top: 10px;
}
.product-info img {
  object-fit: cover;
  width: 55%;
  height: 70%;
  padding: 0px;
  position: absolute;
  left: 4%;
  animation: slideright 1s forwards;
}

.product-info {
  padding: 0px;
  /* border: 4px solid black; */
  height: 440px;
}
.product .product-content {
  width: 600px;
  padding: 0px;
  font-size: 23px;
  font-family: "roboto";
  height: 330px;
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 6) 0%,
    rgba(239, 139, 51, 8) 100%
  );
  position: relative;
  top: 50%;
  left: 21%;
  color: white;
  border-radius: 0px 50px;
  animation: slideRight 1s forwards;
}
.heading1 {
  text-align: center;
  font-size: 60px;
  font-family: "roboto";
  position: relative;
  top: 20px;
}
.product-heading {
  font-family: "playfair Display";
  text-align: center;
  font-size: 40px;
  padding-top: 10px;
}

.heading-data {
  text-align: center;
  font-size: 40px;
  position: relative;
  top: 0px;
  font-family: "Playfair Display";
  animation: bounce 2s;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.para {
  padding: 20px;
  font-family: "roboto";
}

.product-content .product-button {
  position: absolute;
  left: 72%;
  bottom: 4%;
  width: 130px;
  padding: 5px 10px;
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
  color: white;
}

.product-content .product-button:hover {
  background-color: rgb(247, 109, 37);
  color: white;
  border: 1px solid #ef8b33;
}
.contact-button {
  position: relative;
  top: 250px;
  left: 430px;
  padding: 10px 15px;
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
  color: white;
}
.contact-button:hover {
  background-color: rgb(247, 109, 37);
}

@media (min-width: 769px) and (max-width: 880px) {
  .product .product-content {
    width: 73%;
    position: relative;
    top: 50%;
    left: 95px;
    height: 278px;
    padding: 0px 8px;
  }
  .para {
    padding: 0px 11px;
    font-family: "roboto";
    font-size: 17px;
  }
  .product-content .product-button {
    position: absolute;
    left: 70%;
    bottom: 19px;
    width: 130px;
    border-radius: 5px;
    font-size: 16px;
  }
  .contact-button {
    position: relative;
    top: 255px;
    left: 155px;
    margin-left: 100px;
    font-size: 14px;
  }
  .product-info img {
    width: 80%;
    object-fit: cover;
    height: 35%;
    padding: 0px;
    position: absolute;
    left: 4%;
    animation: slideright 1s forwards;
  }
}
 @media (max-width: 768px) {
   .para-data {
     position: relative;
    top: 360px;
  }
  .product-head1 {
    position: relative;
    top: 390px;
    font-size: 20px;

  } 
  .product-info img {
    width: 94%;
    left: 10px;
    height: 170px;
    object-fit: cover;
  }
  .product-heading strong {
    font-size: 19px;
  }
  .product .product-content {
    width: 105%;
    position: relative;
    top: 40%;
    left: -9px;
    height: 290px;
    padding: 0px 8px;
  }
  .para {
    padding: 0px 6px;
    font-family: "roboto";
    font-size: 16px;
  }
  .product-content .product-button {
    position: absolute;
    left: 60%;
    bottom: 7px;
    width: 130px;
    border-radius: 5px;
    font-size: 16px;
  }
  .contact-button {
    position: relative;
    top: 185px;
    left: -15px;
    margin-left: 100px;
    font-size: 14px;
  }
   .iparking-top{
    position: relative;
    top: -190px;
  }
  
.iparking-top2{
  top: 190px;
}
} 

@media only screen and (min-width: 992px) {
  .product-complete {
    margin: 0;
    margin-top: 30px;
  }
  .product-data-top {
    margin-top: 30px;
  }
  .product-info img {
    object-fit: cover;
  }

  .product .product-content {
    position: relative;
    left: 200px;
    top: 65%;
    height: 327px;
    padding: 10px;
  }
  .para {
    padding: 15px;
    font-family: "roboto";
    font-size: 23px;
  }

  .product-content .product-button {
    position: absolute;
    left: 72%;
    bottom: 5%;
    width: 150px;
    border-radius: 5px;
  }
  .contact-button {
    position: relative;
    top: 320px;
    left: 150px;
    margin-left: 300px;
  }
}



.product-info2 img {
  width: 55%;
  height: 70%;
  padding: 0px;
  position: absolute;
  left: 41%;
  animation: slideRight 1s forwards;
}

.product-info2 {
  padding: 0px;
  /* border: 4px solid black; */
  height: 440px;
}
.product .product-content2 {
  width: 600px;
  padding: 0px;
  font-size: 23px;
  font-family: "roboto";
  height: 330px;
  background-color: rgba(1, 5, 33, 0.8);
  /* border: 4px solid red; */
  position: relative;
  top: 50%;
  right: 21%;
  color: white;
  border-radius: 50px 0px;
  animation: slidebottom 1s forwards;
}
.product-heading {
  font-family: "playfair Display";
  text-align: center;
  font-size: 38px;
}

.para2 {
  padding: 20px;
  font-family: "roboto";
}

.product-content2 .product-button {
  position: relative;
  left: 72%;
  bottom: 19%;
  width: 130px;
  padding: 5px 10px;
  border-radius: 35px;
  background-color: rgb(247, 109, 37);
  color: white;
}
.product-content2 .product-button:hover {
  background-color: rgb(53, 57, 86);
}
.contact-button2 {
  position: relative;
  top: 250px;
  left: 430px;
  padding: 10px 15px;
  border-radius: 35px;
  background-color: rgb(247, 109, 37);
  color: white;
}
.contact-button2:hover {
  background-color: rgb(53, 57, 86);
}
@media (min-width: 769px) and (max-width: 880px) {
  .product-info2 img {
    width: 75%;
    height: 33%;
    padding: 0px;
    position: absolute;
    left: 21%;
    animation: slideRight 1s forwards;
  }
  .product .product-content2 {
    width: 550px;
    padding: 0px;
    font-size: 23px;
    font-family: "roboto";
    height: 350px;
    background-color: rgba(1, 5, 33, 0.8);
    /* border: 4px solid red; */
    position: relative;
    top: 40%;
    right: 15%;
    color: white;
    border-radius: 50px 0px;
    animation: slidebottom 1s forwards;
  }
  .para2 {
    padding: 20px;
    font-family: "roboto";
  }
  
  .product-content2 .product-button {
    position: relative;
    left: 72%;
    bottom: 12%;
    width: 130px;
    padding: 5px 10px;
    border-radius: 35px;
    background-color: rgb(247, 109, 37);
    color: white;
  }
  .product-content2 .pro-btn{
    position: relative;
    bottom: 76px;
  }
  .contact-button2 {
    position: relative;
    top: 210px;
    left: 270px;
    padding: 10px 15px;
    border-radius: 35px;
    background-color: rgb(247, 109, 37);
    color: white;
  }
}

@media (max-width: 768px) {
    .ifile-head {
          position: relative;
          top: 260px;
          font-size: 19.4px;
          }
          .product-info2 img {
            width: 100%;
            position: relative;
            top: 232px;
            left: 0;
            height: 200px;
            object-fit: cover;
            }
            .product .product-content2 {
    position: relative;
    right: 10px;
    top: 55%;
    width: 105%;
    padding: 0px;
    height: 278px;
    }

  .product-content2 .product-heading {
    padding: 3.4px;
  }
  .para2 {
    padding: 0px 11px;
    font-size: 16px;
  }
  .product-content2 .product-button {
    position: relative;
    left: 50%;
    bottom: 20px;
    width: 150px;
    border-radius: 5px;
    font-size: 16px;
  }
  .contact-button2 {
    position: relative;
    top: 253px;
    left: -10px;
    margin-left: 100px;
    font-size: 14px;
  }
  .ifile-head2{
    position: relative;
    top: 110px;
    font-size: 19.4px;
    }
    .toll-top{
      position: relative;
      top: -150px;
    }
} 
@media only screen and (min-width: 992px) {
  .product-info2 img {
    object-fit: cover;
  }
  .product .product-content2 {
    position: relative;
    top: 65%;
    height: 350px;
    font-size: 23px;
    padding: 10px;
  }
  .para2 {
    padding: 10px;
    font-family: "roboto";
    font-size: 23px;
  }
  .product-content2 .product-button {
    position: absolute;
    left: 70%;
    bottom: 18px;
    width: 150px;
    border-radius: 5px;
  }
  .contact-button2 {
    position: relative;
    top: 313px;
    left: 0;
    margin-left: 450px;
  }
  .product {
    height: 710px;
    padding: 0px;
  }
}

/* @media (min-width: 770px) and (max-width: 1024px) {
  .product .product-content {
    border: 2px solid red;
    width: 60%;
    height: 84%;
  }
} */

@media (min-width: 1367px) and (max-width: 1920px) {
  .product .product-content {
    position: relative;
    left: 300px;
    top: -30%;
    width: 600px;
    height: 350px;
    padding: 10px;
  }
  .product-info img {
    position: relative;
    top: 10px;
    height: 100%;
    /* width:70%; */
  }
  .contact-button {
    position: relative;
    top: -115px;
    left: 300px;
  }
  .producttop-heading {
    padding: 28px;
  }
  .product .product-content2 {
    position: relative;
    top: -30%;
    left: -300px;
  }

  .product-info2 img {
    position: relative;
    top: 5%;
    height: 90%;
  }
  .contact-button2 {
    position: relative;
    top: -120px;
    left: 150px;
  }
}




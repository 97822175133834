/* About us readmore */

.about-page {
    background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 140px 0 10px;
   
}

.about-page::before {
    content: "";
    width: 65%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 0 0 600px;
    background-color: #010521;
}

.about-page::after {
    content: "";
    background-image: url('/public/about-background.jpg');
    border-radius: 0 0 0 600px;
    width: 65%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 10%;
}


.about-page .about-rightimage {
    position: absolute;
    right: -150px;
    top: 254px;
    z-index: 1;
}

.about-page .about-rightcircle {
    position: absolute;
    right: 70px;
    bottom: 12px;
    z-index: 1;
}

.about-page .about_wrapper {
    position: relative;
}

.about-page .about_wrapper .about-image1 {
    position: absolute;
    left: -60px;
    top: -90px;
    z-index: 1;
}

.about-page .about_wrapper .about-image1 img {
    height: 500px;
    width: 550px;
    border-radius: 0 200px 200px 200px;
}

.about-page .about_wrapper .about-image2 {
    position: absolute;
    left: -108px;
    top: 220px;
    z-index: 1;
}

.about-page .about_wrapper .about-image2 img {
    border-radius: 100px 0 100px 100px;
    border-top: 5px solid white;
    border-right: 5px solid white;
}


.about-page .about_wrapper .about-dot {
    position: absolute;
    left: -120px;
    top: -186px;
}

.about-page .about_wrapper .about-leftcircle {
    position: absolute;
    left: -106px;
    top: 170px;
}



.about-page .about_content {
    padding-left: 55px;
    position: relative;
    z-index: 1;
    top: -60px;
}

.about-page .about_content h2 {
    margin-bottom: 6px;
    margin-right: -12px;
}

.about-page .about_content p {
    color: white;
}


.about-page .about_content h3 {
    color: #ef8b33;
}
.about-page .about_content .text1 {
    margin-bottom: 8px;
}

.about-page .about_content .text2 {
    margin-bottom: 38px;
}

/* .about-page .about-rightimage{
    display: none;
}  */

@media (min-width:820px) and (max-width:1280px){
    .about-page .about_wrapper .about-image1 img {
        position: relative;
         left: 40px;
         top: 20px;
         width: 550px;
     }
     .about-page .about_wrapper .about-image2 img {
         position: relative;
          left: 55px;
          top: 0px;
          width: 280px;
      }
}
@media only screen and (max-width: 880px) {

    .about-page .about_content{
        padding: 30px 0;
        font-size: 21px;
    }
    .about-page .about_wrapper .about-dot {
        position: absolute;
        margin-top: -70px;
    }
    .about-page .about_wrapper .about-image1 img {
       position: relative;
        left: 50px;
        top: -70px;
        width: 550px;
    }
    .about-page .about_wrapper .about-image2 img {
        position: relative;
         left: 0px;
         top: -60px;
         width: 280px;
     }
}

@media only screen and (max-width: 991px) {
    .about-page {
        padding: 64px 0 10px;
    }
    .about-page::before {
        width: 100%;
    }
    .about-page::after {
        width: 100%;
    }
    .about-page .about_wrapper {
        text-align: center;
    }
    .about-page .about_wrapper .about-image1 {
        position: relative;
        left: 20px;
        top: -50px;
    }
    .about-page .about_wrapper .about-image2 {
        left: 110px;
        top: 250px
    }

    .about-page .about_wrapper .about-imagecircle {
        right: 96px;
    }
    .about-page .about_wrapper .about-imagecircle img {
        width: 114px;
    }
    .about-page .about_wrapper .about-dot {
        left: 98px;
        top: -102px;
    }
    .about-page .about_wrapper .about-dot img {
        width: 100px;
    }
    .about-page .about_wrapper .about-leftcircle {
        display: none;
    }
    .about-page .about_wrapper .about-layer img {
        width: 270px;
    }
    .about-page .about_content {
        text-align: center;
        padding-left: 0;
        margin-bottom: 120px;
    }
    .about-page .about_content h6 {
        margin-bottom: 4px;
    }
    .about-page .about_content h2 {
        margin-bottom: 10px;
        padding: 0 130px;
    }
    .about-page .about_content .text1 {
        margin-bottom: 10px;
        padding: 0 95px;
    }
    .about-page .about_content .text2 {
        margin-bottom: 25px;
        padding: 0 90px;
    }


    .about-page .about-rightimage{
        display: none;
    } 
}

@media only screen and (max-width: 768px) {
    .about-page {
        top: -50px;
        padding: 55px 0 10px;
    }
    .about-page .about-backgroundimage {
        right: -680px;
    }
    .about-page .about_wrapper .about-image1 {
        left: -47px;
        top: -70px;
    }
    .about-page .about_wrapper .about-image1 img {
        width: 350px;
        height: 420px;
        border-radius: 0 135px 135px 135px;
    }
    .about-page .about_wrapper .about-image2 {
        left: -40px;
        top: 245px;
    }
    .about-page .about_wrapper .about-image2 img {
        width: 170px;
        border-radius: 65px 0 65px 65px;
    }

    .about-page .about_wrapper .about-imagecircle {
        right: 40px;
        top: -56px;
    }
    .about-page .about_wrapper .about-imagecircle img {
        width: 100px;
    }
    .about-page .about_wrapper .about-dot {
        left: 10px;
        top: -128px;
    }
    .about-page .about-rightcircle {
        display: none;
    }
    .about-page .about_content {
        margin-top: 30px;
        margin-bottom: 114px;
    }
    .about-page .about_content h2 {
        padding: 0 80px;
    }
    .about-page .about_content .text1 {
        margin-bottom: 10px;
        padding: 0 10px;
    }
    .about-page .about_content .text2 {
        margin-bottom: 20px;
        padding: 0;
    }


    .about-page .about-rightimage{
        display: none;
    } 
}

.case-study-banner-top{
  padding: 0;
  margin-top: 120px;
  height: 700px;
}
.case-study-image img{
  animation: scaleUp 1s forwards;
}
.case-study-image{
  position: relative;
}
/* Style the banner_content */
.case-study-banner {
  position: relative;
  bottom: 455px;
  left:10%;
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 8px; 
  z-index: 10; 
  } 
  .case-study-banner .case-study-box{
  position: relative;
  top: 10px;
  left: 430px;
  border: 2px solid rgb(53, 57, 86);
  width: 240px;
  height: 50px;
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
}
.case-study-banner .case-study-box span{
  position: relative;
  top: 5px;
  font-size: 20px;
}
  .case-study-banner  h1{
    position: relative;
    right: 95px;
  font-size: 66px;
  line-height: 72px;  
    font-weight: 700;
  text-transform: uppercase;
}
.case-study-banner p{
  position: relative;
  right: 95px;
  font-size: 20px;
  line-height: 30px;
}
.case-study-banner .case-study-box .box-home{
  color: white;
  }
  .case-study-banner .case-study-box .box_span{
    color: #ef8b33;
  }
  .case-study-banner .case-study-box .arrow{
  padding: 0px 20px;
}
  .case-study-banner .case-study-box .arrow img{
  width: 30px;
  }
   
  
  /* Media Query for mobile view */
  @media (min-width: 769px) and (max-width: 1280px) {
    .case-study-banner-top{
      margin-top: 80px;
      height: 390px;
    }
    .case-study-image img{
      width: 100%;
    }
    .case-study-banner {
    position: relative;
    margin-top: 180px;
    left: 9%;
    padding: 15px;
    width: 80%;
  }
  
  .case-study-banner .case-study-box {
    position: relative;
    left: 190px;
    min-width: 190px;
    height: 40px;
    border: 2px solid rgb(53, 57, 86);
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }
  
  .case-study-banner .case-study-box span {
    font-size: 16px;
    position: relative;
    top: 6px;
  }
  
  .case-study-banner h1 {
    position: relative;
    right: 0;
    font-size: 48px;
    line-height: 54px;
  }
  
  .case-study-banner p {
    position: relative;
    right: 0;
    font-size: 16px;
    line-height: 24px;
  }
  
  .case-study-banner .case-study-box .arrow img {
    width: 25px;
  }
}


   @media (max-width: 768px) {
      .case-study-banner-top{
        margin-top: 80px;
        height: 260px;
      }
      .case-study-image img{
        position: relative;
        width: 100%;
        height: 250px;
      }
  .case-study-banner {
    position: relative;
    margin-top: 67%;
    padding: 10px;
    width: 90%;
  }
  .case-study-banner .case-study-box {
    width: 190px;
    height: 35px;
    left: 27px;
  }
  
  .case-study-banner .case-study-box span {
    font-size: 14px;
    position: relative;
    top: 1px;
  }
  
  .case-study-banner h1 {
    font-size: 36px;
    line-height: 42px;
    position: relative;
    left: 0px;
    margin-right: 30px;
  }
  
  .case-study-banner p {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }
  
  .case-study-banner .case-study-box .arrow {
    width: 10px;
    height: 10px;
  }
} 



/* porfolio css start */
.slider-main1 {
  max-width: 1200px;
  padding: 20px;
}

.slider-main2 {
  background-color: #f9f9f9;
  height: 500px;
  padding: 50px 20px;
  border-radius: 8px;
}

.banner-carousel {
  padding: 40px 0;
  margin-bottom: 20px;
}

.navy-blue-bg {
  background-color: #b565d4;
}

.uber-bg {
  background-color: #1abc9c;
}

.grindr-bg {
  background-color: #e74c3c;
}

/* Text */
.portfolio-column h1,
.portfolio-column h2 {
  font-family: 'Playfair Display' !important;
  font-size: 50px;
  line-height: 55px;
  font-weight: 700;
  position: relative;
  color: white;
}
.portfolio-column p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.portfolio-dot {
  background: rgb(243, 227, 227);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
/* Logos */
.uber-logo{
  padding: 0px 0px 60px 0px;
}
.portfolio-logo {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  gap: 5px;
}

.client-logo img {
  width: 100%;
  height: auto;
  position: relative;
  padding-left: 30px;
}

/* Buttons */
.apd-white-bordered-btn {
  display: inline-block;
  padding: 15px 32px;
  color: white;
  border: 2px solid white;
  text-decoration: none;
  transition: all 0.3s;
  position: relative;
  top: 30px;
  left: 60px;
}

.apd-white-bordered-btn:hover {
  background-color: white;
  color: #333;
}
.portfolio-indicator{
  margin-top: 50px;
}


/* Media Queries */
@media (max-width: 768px) {
  .case-img img{
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .banner-carousel {
    padding: 10px 0; 
    height: 750px;
  }

  .portfolio-column h1,
  .portfolio-column h2 {
    font-size: 28px;
    line-height: 28px;
  }

  .portfolio-column p {
   font-size: 2px;
    margin-top: 5px; 
    margin-bottom: 5px; 
  }

  .portfolio-dot {
    height: 10px;
    width: 10px;
    margin-left: 10px; 
  }
  .portfolio-logo .small-logo img{
    min-height: 0;
    width: 100%;
  }
  .fret-img{
    padding-bottom: 8px;
  }
  .uber-img{
    position: relative;
    padding-left: 40px;
    margin-bottom: 41px;
    padding-bottom: 0;
  }
  .apd-white-bordered-btn {
    padding: 8px 16px; 
    font-size: 12px; 
    top: 5px;
    margin-left: 30px;
  }
  .portfolio-indicator{
    margin-top: 260px;
    background-color: #2a2a2a;
  }
}
@media (min-width: 769px) and (max-width: 1280px){
  .portfolio-slide img{
    max-width: 100%;
  }
  .portfolio-indicator{
    margin-top: 210px;
  }
  .navy-blue-bg, .uber-bg, .grindr-bg{
    height: 700px;
  }
}
/* porfolio css end */

/* button css start */
.button-text{
  margin-top: 60px;
}
.button-text .text-center {
  color: black;
  border: 2px solid brown none;
}
.filter-button-group {
  position: relative;
  padding: 10px;
}
.filter-button-group .btn {
  margin: 10px;
  border: 1px solid black;
}
.filter-button-group .btn:hover {
  /* background-color: orange; */
  background-color: #FFA500; 
border-color: #FFA500;

}
@media (max-width: 768px) {
  .subheading_ourwork{
    margin: 25px;
  }
  .button-text{
    margin-top: 40px;
  }
}

/* button css end */

/* box css start */
.case-study-top{
  border: 3px solid orangered none;
  margin-top: 170px;
}
.element-item {
  margin-left: 10px;
  border: 2px solid rgb(236, 230, 230);
  position: relative;
  width: 370px;
  height: 360px;
  background-color: rgba(201, 190, 190, 0.322);
}
.element-item a {
  text-decoration: none;
}
.element-item h3 {
  color: #2a2a2a;
  font-family: "Playfair Display" !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 1px;
  margin-top: 30px;
  margin-left: 30px;
}
.element-item p {
  font-weight: 400;
  color: gray;
  font-family: "Mulish", sans-serif !important;
  margin: 20px 20px 0 30px;
  font-size: 24px;
}
.element-item:hover {
  background-color: #151e66;
}

.case-study-top .element-item:hover h3,
.case-study-top .element-item:hover p {
  color: white;
}


.element-item1 {
  margin-left: 10px;
  border: 2px solid rgb(236, 230, 230);
  position: relative;
  width: 370px;
  height: 360px;
  background-color: rgba(201, 190, 190, 0.322);
}
.element-item1 a {
  text-decoration: none;
}
.element-item1 h3 {
  color: #2a2a2a;
  font-family: "Playfair Display" !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 1px;
  margin-top: 30px;
  margin-left: 30px;
}
.element-item1 p {
  font-weight: 400;
  color: gray;
  font-family: "Mulish", sans-serif !important;
  margin: 20px 20px 0 30px;
  font-size: 24px;
}
.element-item1:hover {
  background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
}

.case-study-top .element-item1:hover h3,
.case-study-top .element-item1:hover p {
  color: white;
}
.text-right {
  position: relative;
  left: 150px;

}

@media (max-width: 768px) {
  .case-study-top{
    margin-top: 78px;
  }
  .element-item{
    margin-bottom: 30px;
    width: 94%;
    height: 55%;
  }
  .element-item1{
    margin-bottom: 30px;
    width: 94%;
    height: 55%;
  }
  .text-right{
    left: 120px;
    width: 170px;
  }
}


@media (min-width: 769px) and (max-width: 1280px) {
  .element-item{
    margin-bottom: 30px;
  }
  .element-item1{
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 1278px) {
  .element-item{
    margin-left: 10px;
    
  }
  .element-item1{
    margin-left: 10px;
  }
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .element-item{
    margin-left: 55px;
  }
  .element-item1{
    margin-left: 60px;
  }
}
/* box css end */



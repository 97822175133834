


  @keyframes bounce {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(15px);
    }
  }

  @keyframes slidebottom {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideup {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes slideright {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideleft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  .parking-top{
    margin-top: 120px;
  }
.parking-solution{
    padding:0px;
    /* height: 400px; */
}

.parking-image img{
    width: 100%;
    /* height: 450px; */
    animation: slideright 1s forwards;
}
.parking-image{
  padding: 0%;
}



.parking-solution {
  animation: slideright 1s forwards;
  background-color: #ef8b33;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding: 277px 0 px;
  height: 600px;
}
.parking-solution::before {
  content: "";
  width: 90%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 0px 0 400px;
  background-color: #010521;
}

.parking-solution::after {
  content: "";
  background-image: url('/public/case-study-img/parkiimage2.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0px 0 400px;
  width:90%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 10%;
}
.ipark-banner-circleright{
  position: relative;
  width: 200px;
  top: -330px;
  right:220px;

}
.ipark-banner-circle{
  position: relative;
  width: 60px;
  top: -260px;
  left:1080px;
}



/* Style the banner_content */
.ipark-banner {
  position: relative;
  bottom: -180px;
  left: 10%;
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 8px; 
  z-index: 10; 
  }
  .ipark-banner .ipark-box{
  position: absolute;
  top: 160px;
  left: 460px;
  border: 2px solid rgb(53, 57, 86);
  width: 260px;
  height: 50px;
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
  }
  .ipark-banner .ipark-box span{
  position: relative;
  top: 5px;
  font-size: 20px;
  }
  .ipark-banner  h1{
  position: relative;
  right: 95px;
  font-size: 66px;
  line-height: 72px;  
    font-weight: 700;
  text-transform: uppercase;
  }
  .ipark-banner p{
  position: relative;
  right: 95px;
  font-size: 20px;
  line-height: 30px;
  }
  .ipark-banner .ipark-box .box-home{
  color: white;
  }
  .ipark-banner .ipark-box .box_span{
  color: #ef8b33;
  }
  .ipark-banner .ipark-box .arrow{
  padding: 0px 20px;
  }
  .ipark-banner .ipark-box .arrow img{
  width: 30px;
  }
  
  
  /* Media Query for mobile view */
  @media (min-width: 769px) and (max-width: 1280px) {
    .parking-solution{
      margin-top: -30px;
      height: 550px;
    }
  .ipark-banner {
    position: relative;
    margin-top: 20px;
    left: 12%;
    padding: 15px;
    width: 80%;
  }
  
  .ipark-banner .ipark-box {
    position: absolute;
    left: 190px;
    min-width: 190px;
    height: 40px;
    border: 2px solid rgb(53, 57, 86);
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }
  
  .ipark-banner .ipark-box span {
    font-size: 16px;
    position: relative;
    top: 6px;
  }
  
  .ipark-banner h1 {
    position: relative;
    right: 0;
    font-size: 48px;
    line-height: 54px;
  }
  
  .ipark-banner p {
    position: relative;
    right: 0;
    font-size: 19px;
    line-height: 24px;
  }
  
  .ipark-banner .ipark-box .arrow img {
    width: 25px;
  }
  .ipark-banner-circleright{
    position: relative;
    top: -300px;
    right:210px;
  
  }
  .ipark-banner-circle{
    position: absolute;
    top: 250px;
    left:600px;
  }
  }
  
  /* Additional styles for very small devices (portrait phones) */
   @media (max-width: 768px) {
    .parking-top{
      margin-top: 75px;
    }
    .parking-solution{
      height: 300px;
    }
  .ipark-banner {
    position: relative;
    margin-top: -30%;
    padding: 10px;
    width: 90%;
  }
  .ipark-banner .ipark-box {
    width: 190px;
    height: 35px;
    top: 135px;
    left: 50px;
  }
  
  .ipark-banner .ipark-box span {
    font-size: 14px;
    position: relative;
    top: 1px;
  }
  
  .ipark-banner h1 {
    font-size: 36px;
    line-height: 42px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }
  
  .ipark-banner p {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }
  
  .ipark-banner .ipark-box .arrow {
    width: 10px;
    height: 10px;
  }
  .ipark-banner-circleright{
    position: relative;
    top: -200px;
    width: 100px;
    left: -70px;
  }
  .ipark-banner-circle{
    position: absolute;
    top: 180px;
    width: 30px;
    height: 25px;
    left: 270px;
  }
  } 














.case3-heading{
    text-align: center;
    padding: 10px;
    font-family: 'roboto';
}


.intro-parking{
    /* border: 2px solid red; */
    justify-content: space-around;
    padding: 20px;
    font-family: 'roboto';
   

}

.parking-content .parking-heading{
    font-family: 'Playfair Display';
    /* padding-top: 25px; */
    text-decoration: underline;
}

.parking-content .parking-para{
    /* font-size: 20px; */
    padding-right: 20px;
    font-family: 'roboto';
    font-size: 23px;
}

.parking-content{
    padding-left:30px;
    padding-top: 20px;

    
}

.parking-image3{
    /* padding-left:35px ;
    padding-right: 25px; */
    padding-top: 20px;
    /* width: 100%; */
}

.parking-image3 img{
    width: 100%;
    height: 100%;
  
}

.background-park{
    /* border: 4px solid black; */
    padding-top: 20px;
}

.parkback-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.park-para{
    font-size: 23px;
    padding-left: 20px;
    font-family: 'roboto';
}

.park-solution{
    padding: 30px;
}

.parking-sol{
    padding-left: 30px;
    font-size: 23px;
    font-family: 'roboto';
}

.gis-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.parking-sol li{
    font-size: 23px;
    font-family: 'roboto';
}


.gis-para{
    font-size: 23px;
    font-family: 'roboto';
}


.impl-gis{
    padding-left: 30px;
}

.impl-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.impl-gis li{
    font-size: 23px;
    font-family: 'roboto';
}


.impl-para{
    font-size: 23px;
    font-family: 'roboto';
}

.implgis-info{
    padding: 20px 30px;
    /* border:3px solid black; */
}

.implgis-content{
    padding-left: 30px;
}

.implgis-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.implgis-content li{
    font-size: 23px;
    font-family: 'roboto';
}


.implgis-para{
    font-size: 23px;
    font-family: 'roboto';
}


.chal-solupark{
    padding-left: 30px;
}

.csp-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.chal-solupark li{
    font-size: 23px;
    font-family: 'roboto';
}


.csp-para{
    font-size: 23px;
    font-family: 'roboto';
}

.csp-para li{
    font-size: 23px;
    font-family: 'roboto';
}
.future-prop{
    padding: 20px 30px;
}

.future-parapark{
    font-size: 23px;
    font-family: 'roboto';
}

.futurepark1-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.future-prospectspark li{
    font-size: 23px;
    font-family: 'roboto';
}

.future-prospectspark{
    padding-left: 30px;
}

.parkc-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.parkc-para{
    font-size: 23px;
    font-family: 'roboto';
}

.read-top{
  margin-top: 80px;
}

/* Make sure the main-img container is positioned relative */
.productback-image {
  position: relative;
}

/* Style the banner_content */
.readmore-banner {
position: relative;
bottom: 465px;
left: 10%;
/* transform: translate(-50%, -50%); */
text-align: center;
color: white;
padding: 20px;
border-radius: 8px; 
z-index: 10; 
}
.readmore-banner .readmore-box{
position: relative;
top: 10px;
left: 430px;
border: 2px solid rgb(53, 57, 86);
width: 240px;
height: 50px;
border-radius: 35px;
background-color: rgb(53, 57, 86);
}
.readmore-banner .readmore-box span{
position: relative;
top: 5px;
font-size: 20px;
}
.readmore-banner  h1{
position: relative;
right: 95px;
font-size: 66px;
line-height: 72px;  
  font-weight: 700;
text-transform: uppercase;
}
.readmore-banner p{
position: relative;
right: 95px;
font-size: 20px;
line-height: 30px;
}
.readmore-banner .readmore-box .box-home{
color: white;
}
.readmore-banner .readmore-box .box_span{
color: #ef8b33;
}
.readmore-banner .readmore-box .arrow{
padding: 0px 20px;
}
.readmore-banner .readmore-box .arrow img{
width: 30px;
}


/* Media Query for mobile view */
@media (min-width: 769px) and (max-width: 1280px) {

.readmore-banner {
  position: relative;
  margin-top: 180px;
  left: 10%;
  padding: 15px;
  width: 80%;
}

.readmore-banner .readmore-box {
  position: relative;
  left: 190px;
  min-width: 190px;
  height: 40px;
  border: 2px solid rgb(53, 57, 86);
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
}

.readmore-banner .readmore-box span {
  font-size: 16px;
  position: relative;
  top: 6px;
}

.readmore-banner h1 {
  position: relative;
  right: 0;
  font-size: 48px;
  line-height: 54px;
}

.readmore-banner p {
  position: relative;
  right: 0;
  font-size: 16px;
  line-height: 24px;
}

.readmore-banner .readmore-box .arrow img {
  width: 25px;
}
}

/* Additional styles for very small devices (portrait phones) */
 @media (max-width: 768px) {
  .read-top{
    margin-top: 30px;
  }  
.readmore-banner {
  position: relative;
  margin-top: 72%;
  padding: 10px;
  width: 90%;
}
.readmore-banner .readmore-box {
  width: 200px;
  height: 35px;
  left: 25px;
  top: -8px;
}

.readmore-banner .readmore-box span {
  font-size: 14px;
  position: relative;
  top: 1px;
}

.readmore-banner h1 {
  font-size: 36px;
  line-height: 42px;
  position: relative;
  left: 0px;
  margin-right: 40px;
}

.readmore-banner p {
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 0px;
  margin-right: 40px;
}

.readmore-banner .readmore-box .arrow {
  width: 10px;
  height: 10px;
}
} 






.our-heading{
  text-align: center;
  font-family: 'roboto';
  font-size: 50px;
  animation: bounce 1s forwards;
}

.productmore-heading{
  padding:20px ;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Playfair Display';
  font-size: 40px;
  animation: scaleUp 1s forwards;
}
.product-all{
  margin-top: 40px;
}

.productmore-info{
  position: relative;
  /* text-align:center; */
  overflow: hidden;
  /* box-shadow:5px 0 5px #808080; */
  border: 3px solid #808080;
  border-radius: 2px;
  width: 555px;
  animation: scaleUp 1s ease-in-out;
  /* border: 1px solid black; */
}
@keyframes scaleUp {
  from {
      transform: scale(0.5);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 1;
  }
}
.productmore-info img{
  width: 100%;
  height: 460px;
  transform: scale(1);
  transition: all 0.5s linear 0s;
  
}
.productmore-info:hover img{
  transform: scale(1.1);
  transition:transform 0.5s linear 0s;
  box-shadow:5px 0 5px #dfd9d9;
}
.productmore-info .productmore-content{
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  color: white;
  opacity: 0;
  /* padding: 30px; */
  font-size: 22px;
  font-family: 'roboto';
  border-radius: 2px;
  background: rgba(0,0,0,0.3);
  transition: all 0.1s ease-in-out;
  border: 2px solid rgb(18, 17, 17);


}
.productmore-info:hover .productmore-content{
  opacity: 1;
}

.productmore-info .productmore-content ul{
  padding-left: 30px;
  padding-right: 10px;
}

.benefits {
  font-family:'Playfair Display' ;
  padding-left: 20px;
  animation: slideRight 1s forwards;
  /* border: 3px solid red; */
}

.benefits ul{
  padding-left: 20px;
  padding-right: 10px;
  font-size: 23px;
  font-family: 'roboto';
}


.product2-info{
  position: relative;
  /* text-align:center; */
  overflow: hidden;
  /* box-shadow:5px 0 5px #808080; */
  border: 3px solid #808080;
  border-radius: 2px;
  animation: scaleUp 1s ease-in-out;
  width: 557px;
  /* border: 1px solid black; */
}
@keyframes scaleUp {
  from {
      transform: scale(0.5);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideleft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.product2-info img{
  width: 100%;
  height: 480px;
  transform: scale(1);
  transition: all 0.5s linear 0s;
  
}
.product2-info:hover img{
  transform: scale(1.1);
  transition:transform 0.5s linear 0s;
  box-shadow:5px 0 5px #dfd9d9;
}
.product2-info .product2-content{
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  color: white;
  opacity: 0;
  /* padding: 30px; */
  font-size: 22px;
  font-family: 'roboto';
  border-radius: 2px;
  background: rgba(0,0,0,0.3);
  transition: all 0.1s ease-in-out;
  border: 2px solid rgb(18, 17, 17);


}
.product2-info:hover .product2-content{
  opacity: 1;
}

.product2-info .product2-content ul{
  padding-left: 30px;
  padding-right: 10px;
}
.productmore-content .key-title{
  padding-left: 20px;
  /* text-decoration: underline; */
}

.product2-content .key-title{
  padding-left: 20px;
  /* text-decoration: underline; */
}
.benefits-title{
  /* text-decoration: underline; */
  padding-left: 10px;
}

.benefits2 {
  font-family:'Playfair Display' ;
  padding-left: 20px;
  animation: slideleft 1s forwards;
  
  /* border: 3px solid red; */
}

.benefits2 ul{
  padding-left: 20px;
  padding-right: 10px;
  font-size: 23px;
  font-family: 'roboto';
}

.para-bottom{
  text-align: center;
  font-family: 'roboto';
  font-size: 23px;
  padding-top: 10px;
  animation: bounce 1s forwards;
}

@media (min-width: 769px) and (max-width:1280px){
  .productmore-info .productmore-content{
    padding: 10px;
    
  }
  .productmore-info .productmore-content ul p{
    font-size: 20px;
  }

  .product2-info.product2-content{
    padding-top: 10px;
    
  }
  .product2-info .product2-content ul p{
    font-size: 22px;
  }
  .benefits{
    margin-top: 40px;
  }
  .benefits ul{
    font-size: 25px;
  }

  .benefits2 ul{
    font-size: 25px;
  }

  .para-bottom{
    font-size: 25px;
  }

}

@media (max-width:768px){
  .productmore-info {
    width: 100%;
    height: 470px;
  }
  .productmore-info img{
  width: 100%;
  height: 450px;
  padding: 0px;
  object-fit: cover;
  }
  .productmore-detail {
    padding: 0px;
  }

  .productmore-info .productmore-content{
    padding-top: 10px;
    
  }
  .productmore-info .productmore-content ul{
    font-size: 16px;
    padding-right: 0px;
  }
.product2-info{
  width: 100%;

}
  .product2-info .product2-content{
    padding-top: 10px;
    
  }
  .product2-info .product2-content ul{
    font-size: 16px;
  }
}
/* About us */

.choose-us-section {
  background-color: #ef8b33;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding: 80px 0 0px;
}
.choose-us-section::before {
  content: "";
  width: 65%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 0 0 400px;
  background-color: #010521;
}

.choose-us-section::after {
  content: "";
  background-image: url("/public/about-background.jpg");
  border-radius: 0 0 0 400px;
  width: 65%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 10%;
  background-repeat: no-repeat;
  background-size: cover;
}
.choose-us-section .about-rightimage {
  position: absolute;
  right: -150px;
  top: 254px;
  z-index: 1;
}

.choose-us-section .about-rightcircle {
  position: absolute;
  right: 100px;
  bottom: 30px;
  z-index: 1;
}
.choose-us-section .why_us_wrapper .whyus_img {
  position: absolute;
  left: 32px;
  top: 100px;
  z-index: 1;
}

.choose-us-section .why_us_wrapper .whyus_img img {
  border-radius: 200px 0 200px 200px;
  border-top: 5px solid white;
  border-right: 5px solid white;
  margin-top: -10px;
}

.choose-us-section .whyus_content .read_more .arrow {
  position: relative;
  left: 5px;
  bottom: 10px;
}
.choose-us-section .why_us_wrapper .about-imagecircle {
  position: absolute;
  right: -22px;
  top: -130px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.choose-us-section .why_us_wrapper .years:hover .about-imagecircle {
  transform: translateY(-5px);
}

.choose-us-section .why_us_wrapper .whyus-dot {
  position: absolute;
  left: 10px;
  top: -0px;
}

.choose-us-section .why_us_wrapper .about-leftcircle {
  position: absolute;
  left: -106px;
  top: 170px;
}

.choose-us-section .why_us_wrapper .about-layer {
  position: absolute;
  left: 480px;
  top: 240px;
}

.choose-us-section .whyus_content {
  padding-left: 25px;
  position: relative;
  z-index: 1;
}

.choose-us-section .whyus_content h2 {
  margin-bottom: 16px;
  margin-right: -12px;
}

.choose-us-section .whyus_content p {
  color: white;
  padding: 10px;
  justify-content: center;
}
.whyus_content ul li {
  color: white;
  padding: 5px;
}
.whyus_content ul {
  padding-left: 10px;
}

.choose-us-section .whyus_content h2 {
  color: #ef8b33;
}
.choose-us-section .whyus_content .read_more {
  background-color: #ef8b33;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  border-radius: 30px;
  padding: 15px 25px 15px 43px;
  text-align: center;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: -25px;
  left: 30px;
}
.choose-us-section .whyus_content .read_more:hover {
  background-color: #010521;
}

@media only screen and (max-width: 991px) {
  .choose-us-section {
    padding: 64px 0 10px;
  }
  .choose-us-section::before {
    width: 100%;
  }
  .choose-us-section::after {
    width: 100%;
  }
  .choose-us-section .why_us_wrapper {
    text-align: center;
  }
  .choose-us-section .why_us_wrapper .whyus_img {
    position: relative;
    left: 20px;
    top: -50px;
  }
  .choose-us-section .why_us_wrapper .about-imagecircle {
    right: 96px;
  }
  .choose-us-section .why_us_wrapper .about-imagecircle img {
    width: 114px;
  }
  .choose-us-section .why_us_wrapper .whyus-dot {
    left: 98px;
    top: -102px;
  }
  .choose-us-section .why_us_wrapper .whyus-dot img {
    width: 100px;
  }
  .choose-us-section .why_us_wrapper .about-leftcircle {
    display: none;
  }
  .choose-us-section .why_us_wrapper .about-layer img {
    width: 270px;
  }
  .choose-us-section .whyus_content .read_more .arrow {
    position: relative;
    left: 5px;
    bottom: 10px;
  }
  .choose-us-section .whyus_content {
    text-align: center;
    left: 90px;
    margin-bottom: 90px;
  }

  .choose-us-section .whyus_content h2 {
    margin-bottom: 10px;
    padding: 0 130px;
  }
  .choose-us-section .whyus_content .read_more {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 20px;
  }
  .choose-us-section .whyus_content .read_more:hover {
    background-color: #010521;
  }

  .choose-us-section .about-rightimage {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .choose-us-section {
    padding: 95px 0 0px;
  }
  /* .choose-us-section .about-backgroundimage {
        right: -680px;
    } */
  .choose-us-section .why_us_wrapper .whyus_img {
    left: 30px;
  }
  .choose-us-section .why_us_wrapper .whyus_img img {
    width: 400px;
    border-radius: 0 135px 135px 135px;
  }
  /* .choose-us-section .why_us_wrapper .about-image2 {
        left: 20px;
        top: 240px;
    }
    .choose-us-section .why_us_wrapper .about-image2 img {
        width: 170px;
        border-radius: 65px 0 65px 65px;
    } */
  .choose-us-section .whyus_content .read_more .arrow {
    position: relative;
    left: 5px;
    bottom: 10px;
  }
  .choose-us-section .why_us_wrapper .about-imagecircle {
    right: 40px;
    top: -56px;
  }
  .choose-us-section .why_us_wrapper .about-imagecircle img {
    width: 100px;
  }
  .choose-us-section .why_us_wrapper .whyus-dot {
    left: 10px;
    top: -98px;
  }
  .choose-us-section .about-rightcircle {
    display: none;
  }

  .choose-us-section .whyus_content {
    left: 0px;
    top: 20px;
    text-align: center;
    width: 340px;
  }
  .choose-us-section .whyus_content h2 {
    padding: 0 0px;
  }
  /* .choose-us-section .whyus_content .text1 {
        margin-bottom: 10px;
        padding: 0 10px;
    }
    .choose-us-section .whyus_content .text2 {
        margin-bottom: 20px;
        padding: 0;
    } */
  .choose-us-section .whyus_content .read_more {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 24px;
    left: -10px;
  }

  .choose-us-section .whyus_content .read_more:hover {
    background-color: #010521;
  }
  .choose-us-section .whyus_content .read_more figure {
    margin-left: 6px;
  }
  .choose-us-section .whyus_content .read_more figure img {
    width: 16px;
  }

  .choose-us-section .about-rightimage {
    display: none;
  }
}

@media only screen and (max-width: 1278px) {
  .choose-us-section .why_us_wrapper .whyus-dot {
    left: 0px;
    top: -18px;
  }
}
@media only screen and (max-width: 1024px) {
  .choose-us-section .why_us_wrapper .whyus_img img {
    position: relative;
    top: 0px;
    left: -25px;
    width: 80%;
    height: 70%;
  }
}
@media (min-width:769px) and (max-width:1280px) {
    .choose-us-section {
        padding: 95px 0 0px;
      }
      .choose-us-section .whyus_content {
        left: 0;
margin-bottom: 0;
      }
}

 /* about css */
 .about-top1{
  margin-top: 120px;
}
/* .about-top2{
  margin-top: 190px;
  margin-bottom: 80px;
} */
.about-para-info{
  margin-top: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}
 .about-landscape{
  /* border: 5px solid rgb(152, 9, 88); */
  padding: 0px;
  height:550px;
  margin-bottom: 140px;
 }
 .main-img img{
  object-fit: cover;
  animation: scaleUp 1s forwards;
 }
 @keyframes scaleUp {
  from {
      transform: scale(0.5);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 1;
  }
}


/* Make sure the main-img container is positioned relative */
.main-img {
  position: relative;
}

/* Style the banner_content */
.about_banner {
  position: relative;
  bottom: 465px;
  left: 10%;
  /* transform: translate(-50%, -50%); */
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 8px; 
  z-index: 10; 
}
.about_banner .about-box{
  position: relative;
  top: 10px;
  left: 430px;
  border: 2px solid rgb(53, 57, 86);
  width: 240px;
  height: 50px;
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
}
.about_banner .about-box span{
  position: relative;
  top: 5px;
  font-size: 20px;
}
.about_banner  h1{
  position: relative;
  right: 95px;
  font-size: 66px;
  line-height: 72px;  
    font-weight: 700;
  text-transform: uppercase;
}
.about_banner p{
  position: relative;
  right: 95px;
  font-size: 20px;
  line-height: 30px;
}
.about_banner .about-box .box-home{
  color: white;
}
.about_banner .about-box .box_span{
  color: #ef8b33;
}
.about_banner .about-box .arrow{
  padding: 0px 20px;
}
.about_banner .about-box .arrow img{
  width: 30px;
}

  
/* Media Query for mobile view */
@media (min-width: 768px) and (max-width: 1280px) {
  .about-landscape{
    height: 279px;
  }
  .main-img img{
    width: 100%;
  }
  .about_banner {
    position: absolute;
    bottom: 5rem;
    margin-left: -0.1rem;
    padding: 15px;
    width: 80%;
  }

  .about_banner .about-box {
    position: relative;
    margin-left:-240px;
    min-width: 190px;
    height: 40px;
    border: 2px solid rgb(53, 57, 86);
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }

  .about_banner .about-box span {
    font-size: 17px;
    position: relative;
    top: 6px;
  }

  .about_banner h1 {
    position: relative;
    right: 0;
    font-size: 48px;
    line-height: 54px;
  }

  .about_banner p {
    position: relative;
    right: 0;
    font-size: 19px;
    line-height: 24px;
  }

  .about_banner .about-box .arrow img {
    width: 25px;
  }
}

/* Additional styles for very small devices (portrait phones) */
@media (max-width: 767px) {
  .about-landscape{
    margin-top: -35px;
    height: 153px;
  }
  .main-img img{
    width: 100%;
    min-height: 250px;
  }
  .about_banner {
    /* border: 2px solid red; */
    position: relative;
    margin-top: 67%;
    padding: 10px;
    width: 90%;
  }
  .about_banner .about-box {
    /* border: 2px solid red; */
    width: 150px;
    height: 35px;
    left: 70px;
  }

  .about_banner .about-box span {
    font-size: 14px;
    position: relative;
    top: 1px;
  }

  .about_banner h1 {
    font-size: 36px;
    line-height: 42px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }

  .about_banner p {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }

  .about_banner .about-box .arrow {
    width: 10px;
    height: 10px;
  }
}








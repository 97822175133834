.testing-sec{
    /* border: 2px solid red; */
    justify-content: space-around;
    /* margin-top: 70px; */
}
.testing-top{
  margin-top: 110px;
}
.testing-data{
  border: 1px ridge none;
  transition: transform 0.8s ease;
}

.test-image{
    padding: 30px;
    transition: transform 0.3s ease;
}

.test-image img{
    width: 100%;
    height: 300px;
    /* padding-top: 30px; */
    animation: slidebottom 1s forwards;
object-fit: cover;  
}
.testing-data:hover{
  box-shadow: 1px 1px 20px 1px rgba(97, 94, 250, 0.61);
}
.testing-data:hover .test-image {
  transform: scale(1.1);
}

.test-heading{
    font-family: 'Playfair Display';
    padding: 25px;
    text-align: center;
}

.test-content-sec .test-para{
    font-size: 21px;
    padding-left: 20px;
    font-family: 'roboto';
    justify-content: center;

}
.test-content-sec{
    padding-left:30px;
    padding-top: 40px;
    animation: slideRight 1s forwards;
    animation: slideOut 1s forwards;
      
}


@media (max-width: 768px){
    .test-content-sec{
        padding-top: 0px;
    }
    .testing-data:hover .test-image {
        transform: scale(1);
      }
}

@media (max-width: 992px){
    .test-content-sec{
        padding-top: 0px;
    }
}
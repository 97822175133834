/* /Gfooter Start/ */

/* /services start/ */
.footer-section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  /* border-radius: 0 0 600px 0; */
  /* padding: 7px 0 600px; */
  background: #ef8b33;
  height: 280px;
}
.footer-section::before {
  background: #010521;
  content: "";
  position: relative;
  width: 85%;
  height: 95%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0 0 100px 0;
}
.box1 {
  position: relative;
  padding: 16px 0px 16px 0px;
  font-family: "roboto";
  font-size: 17px;
  display: flex;
}
.box1 li {
  list-style: disc;
  color: orange;
}

.box1 .box2 h4 {
  color: white;
  margin-left: 25px;
}
.box1 .box2 a {
  text-decoration: none;
  color: white;
}
.box1 .box2 a:hover {
  color: #ef8b33;
}
.size3 {
  color: azure;
  position: relative;
}
.addr-head {
  position: relative;
  right: 30px;
}
.size p {
  position: relative;
  position: 0, 10, 0, 0;
}

/* icon css start */
.city-icon {
  color: white;
}
.city-icons img {
  height: 80px;
  width: 90px;
  margin-top: 20px;
  transition: transform 0.4s ease;
}
.city-icons:hover img {
  transform: scale(1.3);
}
.city-icon h6 {
  position: relative;
  margin-top: 30px;
  right: 40px;
  font-size: 27px;
}
.city-icon p {
  position: relative;
  font-size: 23px;
  right: 40px;
}
/* icon css end */
.border_line {
  margin: 1rem 0;
  color: white;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
  width: 1000px;
}
/* /social/icons start/ */
.policy-rights {
  display: flex;
  position: relative;
  flex-direction: row;
  /* margin-top: 20px; */
}
.policy-rights a {
  text-decoration: none;
  color: white;
}
.policy-rights a:hover {
  color: #ef8b33;
}

.policy-rights span {
  color: #ef8b33;
}
.policy-rights p {
  color: white;
}
.social-icons li {
  display: inline-flex;
  padding: 8px;
  margin-top: -10px;
}

.footer-rightcircle img {
  position: relative;
  top: 220px;
  left: 60px;
}
.banner-leftlayer img {
  width: 150px;
  height: 250px;
  position: relative;
  bottom: 20px;
  margin-left: 70px;
}

@media (max-width: 768px) {
  .footer-section {
    padding: 7px 0 510px;
  }
  .footer-section .box1 .box2 .footer-data {
    margin-left: 10px;
  }
  .footer-section .box1 .box2 .size3 {
    margin-left: 30px;
  }
  .footer-section .box1 .box2 .city-icons {
    margin-left: 40px;
  }
  .footer-section .box1 .box2 .city-icons .city-icon h6 {
    margin-left: 50px;
  }
  .footer-section .box1 .box2 .city-icons .city-icon p {
    margin-left: 50px;
  }
  .border_line {
    width: 310px;
  }
  .policy-rights {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 992px) {
  .footer-section .box1 .box2 .city-icon {
    margin-left: 50px;
  }
  .border_line {
    width: 1000px;
  }
  .banner-leftlayer img {
    left: 50px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .social-icons ul {
    margin-right: -40px;
  }
  .border_line {
    width: 800px;
  }
}
@media (min-width: 769px) and (max-width: 880px) {
  .border_line {
    width: 620px;
  }
}
@media only screen and (max-width: 1278px) {
  .banner-leftlayer img {
    left: 30px;
  }
}

/* scroll css start*/
.scroll-top {
  position: fixed;
  bottom: 10px;
  right: 17px;
  z-index: 99;
  cursor: pointer;
  /* display: none; */
}

.scroll-top::after {
  z-index: -1;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 45px;
  height: 45px;
  animation: ripple 1.6s ease-out infinite;
  opacity: 0;
  /* background-image: -webkit-linear-gradient(0deg, orange 0%, orangered 100%); */
  border-radius: 100%;
  box-sizing: border-box;
}

@keyframes ripple {
  0% {
    transform: scale(0.7);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.6);
    opacity: 0.5;
  }
}

.scroll-top img {
  width: 58px;
  /* background-color:#ef8b33; */
  border-radius: 50%;
  object-fit: cover;
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .banner-leftlayer img {
    left: 50px;
  }
  .border_line {
    width: 1150px;
  }
}
/* scroll css end */

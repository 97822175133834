
/* Style the banner_content */
.contact-banner-content {
  position: relative;
  bottom: 465px;
  left: 10%;
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 8px; 
  z-index: 10; 
}
  .contact-banner-content .contact-box{
    position: relative;
    top: 10px;
    left: 430px;
    border: 2px solid rgb(53, 57, 86);
    width: 240px;
    height: 50px;
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }
  .contact-banner-content .contact-box span{
  position: relative;
  top: 5px;
  font-size: 20px;
}
.contact-banner-content  h1{
  position: relative;
  right: 95px;
  font-size: 66px;
  line-height: 72px;  
    font-weight: 700;
  text-transform: uppercase;
}
  .contact-banner-content p{
  position: relative;
  right: 95px;
  font-size: 20px;
  line-height: 30px;
  }
  .contact-banner-content .contact-box .box-home{
  color: white;
  }
  .contact-banner-content .contact-box .box_span{
  color: #ef8b33;
}
.contact-banner-content .contact-box .arrow{
  padding: 0px 20px;
  }
  .contact-banner-content .contact-box .arrow img{
  width: 30px;
  }
  
  
  /* Media Query for mobile view */
  @media (min-width: 769px) and (max-width: 1280px) {
    .contact-top{
      max-height: 410px;
    }
    .contact-top img{
      width: 100%;
    }
  .contact-banner-content {
    position: relative;
    margin-top: 180px;
    left: 9%;
    padding: 15px;
    width: 80%;
  }
  
  .contact-banner-content .contact-box {
    position: relative;
    left: 190px;
    min-width: 190px;
    height: 40px;
    border: 2px solid rgb(53, 57, 86);
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }
  
  .contact-banner-content .contact-box span {
    font-size: 18px;
    position: relative;
    top: 6px;
  }
  
  .contact-banner-content h1 {
    position: relative;
    right: 0;
    font-size: 48px;
    line-height: 54px;
  }
  
  .contact-banner-content p {
    position: relative;
    right: 0;
    font-size: 19px;
    line-height: 24px;
  }
  
  .contact-banner-content .contact-box .arrow img {
    width: 25px;
  }
  }
  
  /* Additional styles for very small devices (portrait phones) */
  @media (max-width: 768px) {
    .contact-top{
      margin-top: -40px;
      max-height: 280px;
    }
    .contact-top img{
      width: 100%;
      height: 260px;
    }
  
  .contact-banner-content {
    position: relative;
    margin-top: 69%;
    padding: 10px;
    width: 90%;
  }
  .contact-banner-content .contact-box {
    width: 190px;
    height: 35px;
    left: 35px;
  }
  
  .contact-banner-content .contact-box span {
    font-size: 14px;
    position: relative;
    top: 1px;
  }
  
  .contact-banner-content h1 {
    font-size: 36px;
    line-height: 42px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }
  
  .contact-banner-content p {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }
  
  .contact-banner-content .contact-box .arrow {
    width: 10px;
    height: 10px;
  }
  } 
  
  
  
  



  
  




  
  /* form css start */
  .contact-top h1{
    text-align: center;
  }
  .contact-top h4{
    text-align: center;
  }
.contact-section {
  background-color:white;
  padding: 124px 0 0px;
  position: relative;
  margin-top: 40px;
}

.contact-section .offer-toplayer {
  position: absolute;
  left: -24px;
  top: 0;
}

.contact-section .offer-bottomlayer {
  position: absolute;
  right: 0;
  bottom: 0;
}

.contact-section .need-section .offer-circleimage {
  position: absolute;
  right: -92px;
  top: 132px;
}

.contact-section .contact_content h2 {
  border: 2px solid;
  margin-bottom: 40px;
}

.contact-section .contact_content h5 {
  margin-bottom: 10px;
}
.contact_content{
  margin-top: 50px;
}
.contact-section .contact-box {
  border: 2px solid rgb(248, 244, 236);
  box-shadow: 1px 1px 57px rgb(0 0 0/ 2%);
  background: var(--e-global-color-white);
  border-radius: 10px;
  padding: 28px 30px 26px 30px;
  margin-bottom: 28px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.contact-section .contact-box .box-image {
  float: left;
  width: 26%;
}

.contact-section .contact-box figure {
  background: rgb(248, 240, 234);
  border-radius: 100px;
  height: 105px;
  width: 105px;
  line-height: 105px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

.contact-section .contact-box figure:hover {
  transform: translateY(-5px);
}

.contact-section .contact-box .box-content {
  position: relative;
  padding-left: 34px;
  margin-top: 14px;
  display: inline-block;
  width: 74%;
}

.contact-section .contact-box h4 {
  font-weight: 700;
  margin-bottom: 4px;
}

.contact-section .contact-box .heading {
  margin-bottom: 6px;
  
}

.contact-section .contact-box p {
  font-size: 15px;
  line-height: 24px;
  /* margin-left: 30px; */
  margin-bottom: 4px;
}

.contact-section .contact-box .box-content a {
  font-size: 15px;
  line-height: 24px;
  padding: 20px 0px;
  color: rgb(14, 13, 13);
  transition: all 0.3s ease-in-out;
}



.contact-section .need-section {
  position: relative;
  top: 40px;
  margin-top: -32px;
  margin-left: 30px;
}
/* 
.contact-section .need_content .faq-image {
  position: absolute;
  right: -20px;
  top: -24px;
  z-index: -1;
} */
.contact-section .need_content {
  position: relative;
  top: -0px;
}
.contact-section .need_content h2 {
  margin-bottom: 26px;
}
/* 
.contact-section .need_content p {
  padding: 0 20px;
  margin-bottom: 30px;
} */

.contact-section .need_content {
  box-shadow: 1px 1px 57px rgb(95 208 243 / 10%);
  border-radius: 10px;
  width: 100%;
  margin-top: 40px;
  padding: 36px 50px;
  background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

.form_style::placeholder {
  color: var(--e-global-color-text);
}

.contact-section .need_content .box300 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 100px;
  font-size: 15px;
  line-height: 26px;
  background-color: white;
  color: var(--e-global-color-text);
  border: 1px solid #dae7fb;
  border-radius: 30px;
  padding: 16px 28px;
  margin-bottom: 20px;
  width: 100%;
  overflow: visible;
  outline: none;
}
.contact-section .need_content .box302 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  box-shadow: 1px 1px 57px rgba(95 208 243 / 7%);
  background-color: white;
  color: var(--e-global-color-text);
  border: 1px solid #dae7fb;
  border-radius: 20px;
  padding: 16px 28px;
  width: 100%;
  height: 136px;
  margin-bottom: 24px;
  resize: none;
  outline: none;
  overflow: auto;
}



.contact_map_section {
  overflow: hidden;
  margin-bottom: -8px;
}


.country-select{
  border-radius: 30px;
  border-color: #dae7fb;
  padding: 15px;
  width:34%;
 
}
.need_content{
  z-index:-1;
}
.box301{
  position: relative;
  border-radius: 30px;
  padding: 15px;
  width:300px;
  border-style:none;
  background-color: rgb(53, 57, 86);
  box-shadow: 1px 1px 57px rgba(95 208 243 / 7%);
 
}
.box17{
  margin-top: 90px;
  display: flex;
}
 .privacy{
   color: rgb(53, 57, 86);
  }
  .privacy:hover{
  color: white;
}

@media (min-width: 769px) and (max-width: 1280px) {
  .country-select{
    width: 32%;
  }
  .contact-add{
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .contact-section {
    padding: 60px 0 0;
    margin-top: 90px;
  }
  .country-select{
    width: 28%;
  }
  .contact-section .contact-box {
    padding: 20px;
    margin-bottom: 20px;
  }

  .contact-section .contact-box .box-image {
    width: 100%;
    float: none;
    text-align: center;
    margin-bottom: 15px;
  }

  .contact-section .contact-box .box-content {
    width: 100%;
    padding-left: 0;
  }

  .contact-section .need-section {
    margin-left: 1px;
  }

  .contact-section .need_content {
    padding: 20px;
    margin-top: 20px;
  }

  .contact-section .need_content .box300,
  .contact-section .need_content .box302 {
    width: 100%;
    font-size: 14px;
    padding: 12px 20px;
  }

  .contact_section .contact-box h4 {
    font-size: 16px;
  }
  .box17 .contact-add{
    padding: 10px;
  }
}

/* For mobile devices with a width up to 480px */
@media (max-width: 480px) {
  .contact-section {
    padding: 40px 0px 0;
  }

  .contact-section .contact-box {
    padding: 15px;
  }

  .contact-section .contact-box .box-image {
    width: 100%;
  }

  .contact-section .contact-box figure {
    height: 80px;
    width: 80px;
    line-height: 80px;
  }

  .contact-section .contact-box .box-content {
    padding-left: 0;
  }

  .contact-section .need_content {
    padding: 15px;
  }

  .contact-section .need_content .box300,
  .contact-section .need_content .box302 {
    font-size: 13px;
    padding: 10px 15px;
  }

  .contact-section .contact-box h4 {
    font-size: 14px;
  }

}

@media (min-width: 1367px) and (max-width: 1920px) {
  .country-select{
    width: 28%;
  }
}
/* form css end */




/* Remove spinners in WebKit browsers (Chrome, Safari) */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinners in Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.top-focus{
    margin-top: 130px;
}
.for-common-effect:hover{
    box-shadow: 1px 1px 20px 1px rgba(249, 140, 31, 0.61);
}
.for-effect:hover{
    box-shadow: 1px 1px 20px 1px rgba(97, 94, 250, 0.61);
}
  .focus-table{
    text-align: center;
    font-family: roboto;
    font-size: 21px;
}
.focus-table p{
    margin-top: 30px;
}
.technology-sec{
    /* border: 2px solid red; */
    justify-content: space-around;
    /* margin-top: 70px; */
}
.testing-top{
  margin-top: 110px;
}
.technology-data{
  border: 1px ridge none;
  transition: transform 0.8s ease;
}

.technology-img{
    padding: 30px;
    transition: transform 0.3s ease;
}

.technology-img img{
    width: 100%;
    height: 300px;
    padding: 0px;
    margin: 0px;
    animation: slidebottom 1s forwards;
    object-fit: cover;  
}
.technology-data{
    transition: transform 0.8s ease;
    border: 1px ridge none;
}
.technology-img{
    transition: transform 0.3s ease;
    padding: 30px;
}
.technology-data:hover .technology-img{
   transform: scale(1.1);
}

.technology-heading{
    font-family: 'Playfair Display';
    padding: 25px;
    text-align: center;
}

.technology-content-sec .technology-para{
    font-size: 21px;
    font-family: 'roboto';
    justify-content: center;

}
.technology-content-sec{
    padding-left:30px;
    padding-top: 40px;
    animation: slideRight 1s forwards;
    animation: slideOut 1s forwards;
      
}


@media (max-width: 768px){
    .top-focus{
        margin-top: 80px;
    }
    .technology-content-sec{
        padding-top: 0px;
    }
    .technology-data:hover .technology-img{
        transform: scale(1);
     }

}

@media (max-width: 992px){
    .technology-content-sec{
        padding-top: 0px;
    }
}



/* code css start */
.career-top {
  position: relative;
  top: 70px;
}
.code-top{
  margin-top: 50px;
}
.career-top  img{
  animation: scaleUp 1s forwards;

}/* Make sure the main-img container is positioned relative */
.career-img {
  position: relative;
}

/* Style the banner_content */
.career-img{
  height: 680px;
}
.career-banner {
  position: relative;
  bottom: 465px;
  left: 10%;
  /* transform: translate(-50%, -50%); */
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 8px; 
  z-index: 10; 
  }
  .career-banner .career-box{
  position: relative;
  top: 10px;
  left: 430px;
  border: 2px solid rgb(53, 57, 86);
  width: 240px;
  height: 50px;
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
  }
  .career-banner .career-box span{
  position: relative;
  top: 5px;
  font-size: 20px;
  }
  .career-banner  h1{
  position: relative;
  right: 95px;
  font-size: 66px;
  line-height: 72px;  
    font-weight: 700;
  text-transform: uppercase;
  }
  .career-banner p{
  position: relative;
  right: 95px;
  font-size: 20px;
  line-height: 30px;
  }
  .career-banner .career-box .box-home{
  color: white;
  }
  .career-banner .career-box .box_span{
  color: #ef8b33;
  }
  .career-banner .career-box .arrow{
  padding: 0px 20px;
  }
  .career-banner .career-box .arrow img{
  width: 30px;
  }
  
  
  /* Media Query for mobile view */
  @media (min-width: 769px) and (max-width: 1280px) {
    .career-top{
      margin-top: 60px;
    }
  }
  @media (min-width: 769px) and (max-width: 880px) {
    .career-top{
      margin-top: -40px;
    }
    .career-img{
      max-height: 410px;
    }
    .career-img img{
      width: 100%;
    }
  .career-banner {
    position: relative;
    margin-top: 180px;
    left: 9%;
    padding: 15px;
    width: 80%;
  }
  
  .career-banner .career-box {
    position: relative;
    left: 190px;
    min-width: 190px;
    height: 40px;
    border: 2px solid rgb(53, 57, 86);
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }
  
  .career-banner .career-box span {
    font-size: 18px;
    position: relative;
    top: 6px;
  }
  
  .career-banner h1 {
    position: relative;
    right: 0;
    font-size: 48px;
    line-height: 54px;
  }
  
  .career-banner p {
    position: relative;
    right: 0;
    font-size: 19px;
    line-height: 24px;
  }
  
  .career-banner .career-box .arrow img {
    width: 25px;
  }
  }
  
  /* Additional styles for very small devices (portrait phones) */
   @media (max-width: 768px) {
    .career-img{
      margin-top: -40px;
      max-height: 280px;
    }
    .career-img img{
      width: 100%;
      height: 260px;
    }
  
  .career-banner {
    position: relative;
    margin-top: 69%;
    padding: 10px;
    width: 90%;
  }
  .career-banner .career-box {
    width: 190px;
    height: 35px;
    left: 35px;
  }
  
  .career-banner .career-box span {
    font-size: 14px;
    position: relative;
    top: 1px;
  }
  
  .career-banner h1 {
    font-size: 36px;
    line-height: 42px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }
  
  .career-banner p {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }
  
  .career-banner .career-box .arrow {
    width: 10px;
    height: 10px;
  }
  } 







.banner1 {
  padding: 50px;
  font-family: Italic;
  background: rgba(61, 64, 235, 0.674);
  
}
.banner-img-thumb {
  background-color: #a0a0f3;
  width: 314px;
  border-radius: 50%;
  margin: auto;
}

.banner-img-thumb .img-fluid {
  height: 320px;
  max-width: 80;
}
.banner-img-thumb .img-fluid:hover {
  transform: rotate(360deg);
  transition: transform 2s;
}
.mainContent {
  margin-top: 40px;
  border: 1px solid black none;
}
.data1 h1 {
  position: relative;
  font-size: 35px;
  color: white;
  top: 10px;
}
.data1 .dot2 {
  line-height: 55px;
  position: relative;
  bottom: 46px;
  margin-left: 28rem;
}
.data1 .dot2::before {
  content: "\A";
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  display: inline-block;
}
.data1 p {
  position: relative;
  color: white;
  font-style: italic;
  font-size: 19px;
  bottom: 25px;
}
.data1 .btn1 {
  border: 2px solid rgb(241, 132, 48);
  padding: 10px;
  height: 20px;
  width: 200px;
  background-color: rgb(241, 132, 48);
  text-decoration: none;
  color: white;
}
.data1 .btn1:hover {
  border: 2px solid rgb(53, 57, 86);
  background-color: rgb(53, 57, 86);
  color: white;
  padding-left: 20px;
}

.data1 .arrow {
  margin-left: 10px;
}
.top-content-wrapper p {
  width: 80%;
  margin: 20px auto;
}

.div_box {
  background: white !important;
}

.div_box .image_box_car {
  overflow: hidden;
  height: 320px;
}

.div_box:hover .image_box_car img {
  transform: scale(1.2);
}

.div_box .image_box_car img {
  transition: all 0.2s ease-in;
  width: 100%;
}

.div_box:hover {
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-out;
}

.content_box_car {
  padding: 30px 27px;
  background-color: #fff;
}
.content_box_car p {
  text-align: justify;
}
@media (max-width: 768px) {
  .career-top {
    top: 40px;                                                                                ;
  }
  .mainContent {
    width: 100%;
  }
}
/* code css end  */


/* section css start */
.inner-wrap {
  border: 2px solid red none;
  margin-top: 60px;
  background: transparent !important;
}
.inner-wrap .image {
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
  padding: 50px;
}
.inner-wrap.image::after {
  contain: " ";
  position: absolute;
}
.content-wrap h2 {
  color: white;
}
.looking-perks-box {
  text-align: center;
  margin-bottom: 35px !important;
}
.looking-perks-box img {
  max-width: 50px;
  margin: 0 auto 20px;
  height: 50px;
}
.looking-perks-box p {
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

/* section css end */


/* recognised css start */
.block-rec{
  border: 2px solid rgb(235, 228, 228);
  margin-top: 100px;
}
.block-rec{
  /* border: 2px solid rgb(79, 250, 88); */
  background-color: rgba(250, 139, 38, 0.08);
}
.block-rec .text {
  position: relative;
  top: 15px;
  font-weight: bold;
  font-size: 41px;
  line-height: 55px;
  font-family: "Playfair Display" !important;
  color: #2a2a2a;
}
.block-rec .recognize_logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.recognize_logo {
  list-style: none;
  padding: 0;
}
.block-rec .recognize_logo img {
  width: 100px;
  height: auto;
  margin: 10px;
}
/* recognised css end */


/* carousel css start */
.con1 {
  position: relative;
  /* top: 50px; */
  padding: 10px;
  /* background-color: rgba(242, 234, 224, 0.674); */
}
.con1 h1 {
  text-align: center;
  position: relative;
  font-family: "Playfair Display" !important;
  
}
.con2 {
  border: 2px solid orangered none;
  height: 470px;
}
.carousel-indicators{
  top: 450px;
}
.slider-inner .slide2::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.slider-inner .slide2 .img-fluid {
  width: 100%;
  height: 460px;
  object-fit: cover;
}
.slider-inner .slide2  .innerSliderText h5{
  position: relative;
  top: 30px;
  right: 450px;
  color: white;
  line-height: 37px;
  font-weight: 800;
  font-size: 1.5rem;
  font-family: "Playfair Display" !important;
  z-index: 1;
}
.slider-inner .slide2  .innerSliderText p {
  position: relative;
  top: 30px;
  right: 130px;
  color: white;
  max-width: 700px;
  font-size: 1rem;
  text-align: left;
  z-index: 1;
}
.carousel-control-prev-icon {
  position: relative;
  right: 90px;
  top: 60px;
  background-color: #2a2a2a;
  border-radius: 35px;
}
.carousel-control-next-icon {
  background-color: #2a2a2a;
  position: relative;
  left: 90px;
  top: 50px;
  border-radius: 35px;
}

/* media query css start */
@media only screen and (max-width: 768px) {
  .slider-inner .slide2 .img-fluid{
    /* border: 2px solid rgb(128, 30, 0); */
    max-width: 100%;
    /* max-height: 480px; */
    object-fit: cover;
  }
  .slider-inner .slide2  .innerSliderText h5{
    position: absolute;
   right: 100px;
    top: -140px;
    width: 190px;
    line-height: 37px;
  font-weight: 800;
  font-size: 1.2rem;
  z-index: 1;
  }
  .slider-inner .slide2  .innerSliderText p {
    position: absolute;
    right: -20px;
    top: -100px;
    width: 290px;
    font-size: 0.84rem;
    letter-spacing: 0px;
    z-index: 1;
  }
  .carousel-control-prev-icon {
    right: 0;
    top: 0px;
  }
  .carousel-control-next-icon {
    left: 0;
    top: 0;
  }
}
@media (min-width: 769px) and (max-width: 880px) {
  .slider-inner .slide2  .innerSliderText h5{
   position: relative;
   right: 220px;
  }
  .slider-inner .slide2  .innerSliderText p {
  position: relative;
  left: -50px;
  }
  .carousel-control-prev-icon {
    right: 50px;
    top: 20px;
  }
  .carousel-control-next-icon {
    left: 50px;
    top: 20px;
  }
}
/* media query css end */
/* carousel css end */

/* Project css start */
.project-bg{
  padding: 0;
}
.project-bg img{
  background-repeat: no-repeat;
  background-size: cover;
 margin-top: 70px;
 height: 450px;
 background-position: center;
  width: 100%;
}

.job-project-heading{
  text-align: center;
  padding: 50px;
  font-family: "Roboto";
}
.job-description-container span{
  position: relative;
  top: 10px;
  font-size: 18px;
  font-weight: 480;
}
.set-data ul{
  list-style: none;
  margin-top: 10px;
}
.good{
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  position: relative;
  right: 30px;
}
.btn-info{
  background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
  transition: 0.3s linear;
  border: none;
}
.btn-info:hover{
  background: rgb(12, 7, 88);

}
.btn-info:hover i{
  margin-left: 10px;
}
.btn-container{
  margin: 40px 60px;  
}
.btn-container .btn{
  width: 220px;
  height: 50px;
  border-radius: 35px;
}
 .btn a{
  text-decoration: none;
  cursor: pointer;
  color: white;
}
.arrow-icon{
  padding-left: 10px;
}
@media (max-width: 768px) {
  .project-bg img{
    height: 200px;
  }
}
/* Project css end */

/* form3 css start */
.form3-top{
  position: relative;
  top: 80px;
}
.form3-top a{
  text-decoration: none;
}
.box34 {
  display: inline-block;
  text-align: center;
  border-style: dashed;
  border-color: #808080;
  /* border: 1px solid black ; */
width: 500px;
margin: 20px;
  padding: 10px; 
  text-decoration: none;

}
@media (max-width: 768px) {
 .box34 {
  width: 350px;
 }
}


.box35 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; 
  margin-top: 50px; 

}
/* form3 css end */

/* detail css start */
.detail-top{
  position: relative;
  top: -15px;
}
.detail-top .detail-button1 {
  border-radius: 35px;
  padding: 10px;
}
.detail-top .apply-btn {
  background: rgb(22, 63, 22);
  color: white;
  border-radius: 5px;
  padding: 10px 14px;
  margin: 20px 500px;
}
@media (min-width: 769px) and (max-width: 880px) {
  .detail-top .apply-btn {
    width: 130px;
    margin: 10px 300px;
  }
}
@media (max-width: 768px) {
  .form3-top{
    top: 30px;
  }
  .detail-top .apply-btn {
    margin: 0;
  }
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .detail-top .apply-btn {
    margin-left: 600px;
  }
}
/* detail css end */

/* formjob openpos css start */
.project-bg-img{
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100%;
  max-height: 400px; 
  aspect-ratio: 1/4;
  position: relative;
  top: 70px;
  left: 0px;
}
.bg-content{
  position: relative;
  bottom: 220px;
}
.kch-btn{
  font-size: 17px;
  background-color: rgb(146, 114, 221);
  padding: 10px 16px;
  border-radius: 20px;
margin-top: 10px;
}




.kh-mb-4{
  margin-bottom: calc(var(--font-size)* 3) !important;
}

.job-opening{
  margin: 0px;
  padding: 0px;
}
.kh-main-container {
  --font-size: 13px;
  box-sizing: border-box;

} 
.kh-main-container *::before{
  box-sizing: border-box;
}
.ki-search::before{
  content: "\f1a8";
}

.kh-input-search input {
  height: 40px !important;
  width: 180px;
  background: transparent;
  border-radius: 3px;
  padding: 1px;
  transition: 0.3s linear;
  outline: none;
  border: 1px solid #D8DDE6 !important;
}
/* .kh-input-search{
  border: 1px solid #D8DDE6 !important;
 
  padding-bottom: 10px;
} */


.drop-icon{
  position: relative;
  left: 50px;
}
.kh-w-100{
  width: 100%;
}
.kh-col-sm-12.kh-mb-16.kh-col-md-12 {
  max-width: 49.9% !important;
  padding: 0 8px;
} 
.kh-align-items-center {
  align-items: center !important;

}
.kh-flex-wrap {
  flex-wrap: wrap !important;
}
.kh-d-flex {
  display: flex !important;
}
.kh-mr-2, .kh-main-container h3.kh-mr-2 {
  margin-right: calc(var(--font-size)* 0.5) !important;
}
.kh-main-container h3{
  font-size: 1.385;
  line-height: 1.923; 
} 

.button-clr{
  /* background-color: red; */
  position: relative;
  right: 25px;
}

.no-jobs{
  font-size: 20px;
  font-weight: 200;
}
.kh-font-weight-600{
  font-weight: 600;
  
}
.kh-text-dark{
  color: #343a40;
}
.kh-text-secondary {
  color: #6c757d !important;
}
.mr-1  {
  margin-right: .25rem !important;
}
.kh-text-secondary {
  color: #6c757d !important;
} 
.kh-main-container h1 {
  font-size: calc(var(--font-size) * 1.846);
  line-height: calc(var(--font-size) * 2.538);
}
#kh-job-widget h1 span {
  font-family: 'Mulish', sans-serif !important;
}

.kh-ml-2 {
  margin-left: calc(var(--font-size)* 0.5) !important;
}
.kh-cursor-pointer{
  cursor: pointer;
}
.kh-icon-lg {
  height: 28px;
  width: 28px;
  font-size: 24px !important;
  line-height: 28px !important;
  font-weight: 300 !important;
}
.kh-main-container h1 {
  font-size: calc(var(--font-size) * 1.846);
  line-height: calc(var(--font-size) * 2.538);
}
.kh-card{
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0.125);
  border-radius: calc(var(--font-size)* 0.50);
}
.kh-card-body{
  flex: 1 1 auto;
  padding: calc(var(--font-size)* 1.25);
}
.kh-list-unstyled {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
}
.kh-overflow-x-hidden {
  overflow-x: hidden;
}
.kh-mb-0 {
  margin-bottom: 0 !important;
}

 .kh-job-card{
  cursor: pointer;
}
.kh-col-sm-12{
  position: relative;
  width: 100%;
  min-height: 1px;
}  

.job-heading{
  text-align: center;
  padding: 50px;
  font-family: "Roboto";
}
*{
  margin: 0px;
  padding: 0px;
  /* justify-content: center;
  align-items: center; */
}



.kh-icon{
  display: none;
}

.kh-job-container:hover .kh-icon .fa-circle-arrow-right{
  color: #d4681c !important;
}

.kch-section {
  margin: 20px auto;
}

.kch-d-flex {
  display: flex;
  
}
 
.kch-align-items-center {
  align-items: center;
}

.kch-justify-content-center {
  justify-content: center;
}

.kch-text-heading {
  font-size: 2rem;
}

.kch-font-weight-700 {
  font-weight: 700;
}

.kch-mt-0 {
  margin-top: 0;
}

.kch-mb-12 {
  margin-bottom: 12px;
}

.kch-text-center {
  text-align: center;
}

.kch-text-description {
  font-size: 1rem;
}

.kch-text-secondary {
  color: #6c757d;
}

.kch-font-weight-600 {
  font-weight: 600;
  /* font-size: 50px; */
}

.kh-font-weight-100{
  font-weight: 100;
}

.kch-mb-16 {
  margin-bottom: 16px;
}

.kh-main-container {
  font-family: 'Roboto', sans-serif;
  color: rgb(0, 0, 0.125);
  box-sizing: border-box;
}

.kh-row {
  display: flex;
  flex-wrap: wrap;
}

.kh-no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.kh-col-lg-3, .kh-col-lg-9, .kh-col-sm-12, .kh-col-md-12 {
  position: relative;
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
  padding-bottom: 15px;
}

.kh-pr-lg-3 {
  padding-right: 1rem;
}

.kh-mb-4 {
  margin-bottom: 1.5rem;
} 

.kh-card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  
}

.kh-job-card:hover{
  border: 1px solid #d4681c;
  color: #d4681c;
} 

.kh-card-body {
  padding: 1.25rem;
}

.kh-list-unstyled {
  padding-left: 0;
  list-style: none;
}

.kh-overflow-x-hidden {
  overflow-x: hidden;
}

.kh-mb-0 {
  margin-bottom: 0;
}

.kh-input-search {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}


.kh-d-md-block {
  display: block !important;
}

.kh-icon {
  display: inline-block;
  fill: currentColor;
  height: 2em;
  width: 2em;
  
}

.kh-icon-sm {
  height: 1.25rem;
  width: 1.25rem;
}

.kh-position-relative {
  position: relative;
}

.kh-w-100 {
  width: 100%;
  
}

.kh-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.kh-mt-3 {
  margin-top: 1rem;
}

.kh-accordion-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: #fff;
  margin-bottom: 1rem;
}

.kh-accordion-title {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kh-font-large {
  font-size: 1.25rem;
}

.kh-accordion-content {
  padding: 1rem;
  display: none;
}

.kh-list-unstyled {
  padding-left: 0;
  list-style: none;
}

.kh-font-normal {
  font-weight: 400;
}

.kh-my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.kh-ml-4 {
  margin-left: 1.5rem;
}
.kh-ml-8{
  margin-left: 1px;
}

.kh-form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.kh-form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.kh-form-check-label {
  margin-bottom: 0;
}

.kh-cursor-pointer {
  cursor: pointer;
}

.kh-text-truncate-2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kh-pl-2 {
  padding-left: 0.5rem;
}

.kh-pl-lg-3 {
  padding-left: 1rem;
}

.kh-mb-3 {
  margin-bottom: 1rem;
}

.kh-font-weight-600 {
  font-weight: 600;
}

.kh-col-md-12 {
  flex: 0 0 auto;
  width: 100%;
}

.kh-job-container {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
}

.kh-job-container:hover .kh-job-title, .kh-job-container:hover .kh-icon{

  color: rgba(212, 104, 28) !important;
}



.kh-h-100 {
  height: 100%;
}

.kh-py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.kh-job-title {
  font-size: 1.25rem;
}


.kh-text-dark {
  color: #343a40;
}

.kh-text-truncate-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kh-mr-2 {
  margin-right: 0.5rem;
}

.kh-text-capitalize {
  text-transform: capitalize;
} 

.kh-dot-xs {
  height: 0.25rem;
  width: 0.25rem;
  border-radius: 50%;
}

.kh-bg-slate-900 {
  background-color: #2d3748;
}

.kh-mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.kh-clear-magin {
  margin: 0;
}

.kh-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.kh-icon-lg {
  height: 1.5rem;
  width: 1.5rem;
  /* position: relative;
  left: 70px; */
}

.kh-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.kh-rotate-315 {
  transform: rotate(315deg);
}

.ki-search::before {
  content: "\f002";
}

.ki-clear-filter::before {
  content: "\f00d";
}

.fa-angle-down::before {
  content: "\f078";
}

.fa-arrow-up-long::before {
  content: "\f0a9";
} 
/* formjob and openpos css end */
.ifile-solution {
    animation: slideright 1s forwards;
    background-color: #ef8b33;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 277px 0 px;
    height: 600px;
  }
  
  .ifile-solution::before {
    animation: slideright 1s forwards;
    content: "";
    width: 90%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 0 0 400px;
    background-color: #010521;
  }
  
  .ifile-solution::after {
    animation: slideright 1s forwards;
    content: "";
    background-image: url("/public/case-study-img/ifilecaseimg.jpg");
    border-radius: 0 0 0 400px;
    width: 90%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 10%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .ifile-banner-circleright {
    position: relative;
    top: -330px;
    right: 770px;
  }
  .ifile-banner-circle {
    position: relative;
    top: -300px;
    left: 450px;
  }
  
  /* Style the banner_content */
  .ifile-banner {
    position: relative;
    bottom: -170px;
    left: 10%;
    text-align: center;
    color: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 10;
  }
  .ifile-banner .ifile-box {
    position: absolute;
    top: 160px;
    left: 460px;
    border: 2px solid rgb(53, 57, 86);
    width: 240px;
    height: 50px;
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }
  .ifile-banner .ifile-box span {
    position: relative;
    top: 5px;
    font-size: 20px;
  }
  .ifile-banner h1 {
    position: relative;
    right: 95px;
    font-size: 66px;
    line-height: 72px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .ifile-banner p {
    position: relative;
    right: 95px;
    font-size: 20px;
    line-height: 30px;
  }
  .ifile-banner .ifile-box .box-home {
    color: white;
  }
  .ifile-banner .ifile-box .box_span {
    color: #ef8b33;
  }
  .ifile-banner .ifile-box .arrow {
    padding: 0px 20px;
  }
  .ifile-banner .ifile-box .arrow img {
    width: 30px;
  }
  
  /* Media Query for mobile view */
  @media (min-width: 769px) and (max-width: 1280px) {
    .ifile-solution {
      margin-top: -30px;
    }
    .ifile-banner {
      position: relative;
      margin-top: 30px;
      left: 12%;
      padding: 15px;
      width: 80%;
    }
  
    .ifile-banner .ifile-box {
      position: absolute;
      left: 190px;
      min-width: 190px;
      height: 40px;
      border: 2px solid rgb(53, 57, 86);
      border-radius: 35px;
      background-color: rgb(53, 57, 86);
    }
  
    .ifile-banner .ifile-box span {
      font-size: 16px;
      position: relative;
      top: 6px;
    }
  
    .ifile-banner h1 {
      position: relative;
      right: 0;
      font-size: 48px;
      line-height: 54px;
    }
  
    .ifile-banner p {
      position: relative;
      right: 0;
      font-size: 19px;
      line-height: 24px;
    }
  
    .ifile-banner .ifile-box .arrow img {
      width: 25px;
    }
    .ifile-banner-circleright {
      position: relative;
      top: -400px;
      right: 370px;
    }
    .ifile-banner-circle {
      position: absolute;
      top: 300px;
      left: 600px;
    }
  }
  
  /* Additional styles for very small devices (portrait phones) */
  @media (max-width: 768px) {
    .ifile-solution {
      padding: 55px 0 10px;
      height: 250px;
    }
    .ifile-banner {
      position: relative;
      margin-top: -49%;
      padding: 10px;
      width: 90%;
    }
    .ifile-banner .ifile-box {
      width: 160px;
      height: 35px;
      top: 130px;
      left: 50px;
    }
  
    .ifile-banner .ifile-box span {
      font-size: 14px;
      position: relative;
      top: 1px;
    }
  
    .ifile-banner h1 {
      font-size: 36px;
      line-height: 42px;
      position: relative;
      left: 0px;
      margin-right: 40px;
    }
  
    .ifile-banner p {
      font-size: 14px;
      line-height: 20px;
      position: relative;
      left: 0px;
      margin-right: 40px;
    }
  
    .ifile-banner .ifile-box .arrow {
      width: 10px;
      height: 10px;
    }
    /* .ifile-banner-circleright{
      position: relative;
      top: -160px;
      width: 60px;
      height: 10px;
      right:170px;
    
    } */
    .ifile-banner-circle {
      position: absolute;
      top: 130px;
      width: 10px;
      height: 5px;
      left: 240px;
    }
  }












.ifile-section{
    margin-top: 100px;
}
.ifile-section .ifile-heading h1{
    font-size: 40px;
    font-weight: 600;
    font-family: 'Playfair Display';
    text-align: center;
    padding: 30px;
}

.ifile-section  .caseifile-image img{
    width: 100%;
    height: 300px;

}

.ifile-section .ifile-content{

    padding-bottom: 15px;
   
}
.ifile-section .ifile-content h2{
    font-family: 'Playfair Display';
    font-weight: 600;
    padding-left: 15px;
}

.ifile-section .ifile-challenge h2{
    font-family: 'Playfair Display';
    font-weight: 600;
    padding-left: 15px;
}


.ifile-section .ifile-challenge{
    padding-top: 30px;
}

.ifile-section .ifile-scenario{
    padding-top: 30px;
}

.ifile-section .ifile-challenge p{
  font-family: 'roboto';
  font-size: 23px;
    padding-left: 15px;
}

.ifile-section .ifile-scenario h2{
    font-family: 'Playfair Display';
    font-weight: 600;
    padding-left: 15px;

}

.ifile-section .ifile-scenario p{
  font-family: 'roboto';
  font-size: 23px;
    padding-left: 15px;
}
.ifile-section .ifile-content p{
  font-family: 'roboto';
  font-size: 23px;;
    /* padding-left: 30px; */
}

.ifile-section .ifile-content .ifile-para{
  font-family: 'roboto';
  font-size: 23px;
    padding-left: 15px;
}



@media only screen and (max-width: 991px) {

    .ifile-section .caseifile-image img{
        width: 100%;
        height: 380px;
        padding-top: 60px;
            }

    .ifile-section .ifile-heading h1{
        font-size: 30px;
    }

    .ifile-section .overview h2{
       position: relative;
       left:-90px;
    }

    .ifile-section .ifile-challenge{
        padding-top: 0px;
    }
    
    .ifile-section .ifile-scenario{
        padding-top: 0px;
    }
}

@media only screen and (max-width:768px) {

  .ifile-section{
    margin-top: 5px;
}
    .ifile-section .caseifile-image img{
        width: 100%;
        height: 100%;
        padding-top: 0px;
        margin-bottom: 20px;

     }



    .ifile-section .overview h2{
        position: relative;
        left:0px;
        
     }

     .ifile-section .ifile-challenge{
        padding-top: 0px;
    }
    
    .ifile-section .ifile-scenario{
        padding-top: 0px;
    }
}
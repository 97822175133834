/* blogs css start */
.blog-top {
  position: relative;
  top: 113px;
}

.box-blog h1 {
  font-family: "fav";
  font-size: 4rem;
}
.box-blog .dot {
  height: 15px;
  width: 15px;
  position: relative;
  right: 350px;
  top: 20px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
.form-control{
  border: 2px solid rgb(245, 243, 238);
border-radius: 20px;
}
.blog-img img{
  animation: scaleUp 1s forwards;
}
.search-icon {
  position: absolute;
  top: 10px;
  right: 90px;
  color: gray; 
  /* pointer-events: none; */
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  .blog-top{
    position: relative;
    top: 80px;
    
  }
  .blog-img img{
    object-fit: cover;
    width: 100%;
    
  }
  .box-blog .dot {
    right: 177px; 
  }
}
@media only screen and (min-width: 992px){
  .box-blog .dot{
    right: 350px;
 
  }
}
/* blogs css end */





/* Make sure the main-img container is positioned relative */
.blog-img {
  position: relative;
  height: 700px;
}

/* Style the banner_content */
.blog-banner {
position: relative;
bottom: 465px;
left: 10%;
/* transform: translate(-50%, -50%); */
text-align: center;
color: white;
padding: 20px;
border-radius: 8px; 
z-index: 10; 
}
.blog-banner .blog-box{
position: relative;
top: 10px;
left: 430px;
border: 2px solid rgb(53, 57, 86);
width: 240px;
height: 50px;
border-radius: 35px;
background-color: rgb(53, 57, 86);
}
.blog-banner .blog-box span{
position: relative;
top: 5px;
font-size: 20px;
}
.blog-banner  h1{
position: relative;
right: 95px;
font-size: 66px;
line-height: 72px;  
  font-weight: 700;
text-transform: uppercase;
}
.blog-banner p{
position: relative;
right: 95px;
font-size: 20px;
line-height: 30px;
}
.blog-banner .blog-box .box-home{
color: white;
}
.blog-banner .blog-box .box_span{
color: #ef8b33;
}
.blog-banner .blog-box .arrow{
padding: 0px 20px;
}
.blog-banner .blog-box .arrow img{
width: 30px;
}


/* Media Query for mobile view */
@media (min-width: 769px) and (max-width: 1280px) {
  .blog-img{
  height: 460px;
  }
  .blog-img img{
  width: 100%;
}
.blog-banner {
  position: relative;
  margin-top: 180px;
  left: 9%;
  padding: 15px;
  width: 80%;
}

.blog-banner .blog-box {
  position: relative;
  left: 190px;
  min-width: 190px;
  height: 40px;
  border: 2px solid rgb(53, 57, 86);
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
}

.blog-banner .blog-box span {
  font-size: 16px;
  position: relative;
  top: 6px;
}

.blog-banner h1 {
  position: relative;
  right: 0;
  font-size: 48px;
  line-height: 54px;
}

.blog-banner p {
  position: relative;
  right: 0;
  font-size: 16px;
  line-height: 24px;
}

.blog-banner .blog-box .arrow img {
  width: 25px;
}
}

/* Additional styles for very small devices (portrait phones) */
 @media (max-width: 768px) {
  .blog-img{
    height: 200px;
  }
  .blog-img img{
    height: 190px;
  }
  .blog-banner {
    position: relative;
    margin-top: 79%;
    padding: 10px;
    width: 90%;
  }
  .blog-banner .blog-box {
    width: 150px;
    height: 35px;
    top: -10px;
    left: 50px;
  }
  
  .blog-banner .blog-box span {
    font-size: 14px;
    position: relative;
    top: 1px;
}

.blog-banner h1 {
  font-size: 36px;
  line-height: 42px;
  position: relative;
  left: 0px;
  margin-right: 40px;
}

.blog-banner p {
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 0px;
  margin-right: 40px;
}

.blog-banner .blog-box .arrow {
  width: 10px;
  height: 10px;
}
} 
















/* blog card css start */
/* Styles for the blog post cards */
.blog-posts {
  padding: 30px 0;
}

.post-item {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.post-item-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.post-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.post-item-description {
  padding: 15px;
  flex: 1;
}

.post-item-description h2 a {
  font-size: 18px;
  color: #333;
  text-decoration: none;
}

.post-item-description p {
  font-size: 14px;
  color: #666;
}

.item-link {
  display: inline-flex;
  align-items: center;
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
}

.item-link i {
  margin-left: 5px;
}

.embed-container,
.fluid-width-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.embed-container iframe,
.fluid-width-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.post-meta-category a {
  font-size: 12px;
  color: hsl(22, 100%, 50%);
  text-decoration: none;
}

.post-meta-date,
.post-meta-comments {
  display: block;
  font-size: 12px;
  color: #999;
}

.post-meta-comments a {
  color: #007bff;
  text-decoration: none;
}





/* Ensure the card takes full height and has a uniform appearance */
.post-item {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.post-item-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.post-image img {
  width: 100%;
  height: 200px; /* Adjust this value as needed */
  object-fit: cover;
}

.post-audio {
  position: relative;
  height: 264px; /* Match this height with the image height */
  overflow: hidden;
}

.post-audio img {

  width: 100%;
  height: 75%;
  object-fit: cover;
}

.post-audio audio {
  position: absolute;
  bottom: 10px; /* Adjust the bottom position */
  left: 10px; /* Adjust the left position */
  right: 10px; /* Adjust the right position */
  background: rgba(0, 0, 0, 0.5); /* Optional: Add a background color for better visibility */
  border-radius: 5px;
}

.post-item-description {
  padding: 15px;
}

.item-link {
  display: inline-flex;
  align-items: center;
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
}

.item-link i {
  margin-left: 5px;
}

.post-meta-category a {
  font-size: 12px;
  /* color: #007bff; */
  text-decoration: none;
  padding:11px;
  color: white;
  text-align: left;
  border:1px solid rgb(240, 133, 48);
  border-radius: 10px;
  background:linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
}

.post-meta-date,
.post-meta-comments {
  display: block;
  font-size: 12px;
  color: #999;
}

.post-meta-comments a {
  color: #007bff;
  text-decoration: none;
}
.post-meta-category{
  position:relative;
 top:10px;
left:50px;

}

/* blog card css end */

/* blogbutton css start */
.content{
  margin-top: 40px;
}
.filter-button-group .blog-btn {
  padding: 10px 20px;
  border: 2px solid transparent;
  color: #000;
  background-color: transparent;
  transition: all 0.3s ease;
  border: 1px solid;
  border-image: linear-gradient(to right #0ff) 1;
  margin: 10px;
}
.filter-button-group .blog-btn:hover {
  background-color: black;
  color: #fff;
}

/* blogbutton css end */

/* token css */
.blog-item {
  text-decoration: none;
}
.box11 {
  margin-top: 30px;
  margin-left: 40px;
  width: 990px;
  border: 1px ridge none;
  text-decoration: none;
  transition: transform 0.8s ease;
}
.vertical-line {
  position: relative;
  left: 40px;
  bottom: 20px;
}
.vertical-line::after {
  content: " ";
  position: absolute;
  top: 0;
  border-left: 2px solid rgba(190, 189, 189, 0.384);
  height: 90px;
}
.box11 .date-month {
  position: relative;
  top: 50px;
  color: #b0b0b0;
  font-weight: 700;
  font-size: 27px;
  font-family: "Mulish", sans-serif !important;
}

.box11 .date-day {
  position: relative;
  top: 45px;
  color: #b0b0b0;
  font-weight: 700;
  font-size: 50px;
  font-family: "Mulish", sans-serif !important;
}
.box11 h3 {
  font-family: "Playfair Display" !important;
  position: relative;
  top: 20px;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
}

.box10 {
  height: 200px;
  width: 350px;
  margin-left: 55px;
  transition: transform 0.3s ease;
}
.box11:hover {
  box-shadow: 1px 1px 20px 1px rgba(97, 94, 250, 0.61);
}

.box11:hover .box10 {
  transform: scale(1.1);
}
.blog-item-image {
  position: relative;
  left: 20px;
}

@media (min-width: 769px) and (max-width:1280px) {
  .box11 {
    width: 730px;
    margin-left: 0;
  }
  .box10{
    position: relative;
    left: -70px;
    height: 160px;
    width: 250px;
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .box11 {
    width: auto;
    margin: 0;
    transition: transform 0.8s ease;
  }
  .box11:hover {
    box-shadow: 1px 1px 20px 1px rgba(97, 94, 250, 0.61);
  }
  .box11:hover .box10 {
    transform: scale(1.1);
  }
  .box11 .date-month {
    top: 35px; 
    font-size: 25px;
  }
  .box11 .date-day {
    top: 29px; 
    font-size: 33px;
  }
  .box11 h3 {
    top: 0; 
    font-size: 27px; 
    line-height: 28px; 
    position: relative;
    right: 17px;
  }
  .blog-item-image{
    left: 0px;
  }
  
  .box10 {
    width: 90%;
    position: relative;
    left: -40px;
    /* right: 45px; */
    margin-top: 15px; 
    transition: transform 0.3s ease;
  }
}

@media only screen and (min-width: 992px){
  .box11{
    width: 100%;
    position: relative;
    right: 40px;
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .box10 {
    margin-left: 150px;
  }
}
/* blogbutton css end */

/* tokenization css start */


/* Style the banner_content */
.token-banner {
position: relative;
bottom: 465px;
left: 10%;
/* transform: translate(-50%, -50%); */
text-align: center;
color: white;
padding: 20px;
border-radius: 8px; 
z-index: 10; 
}
.token-banner .token-box{
position: relative;
top: 10px;
left: 430px;
border: 2px solid rgb(53, 57, 86);
width: 240px;
height: 50px;
border-radius: 35px;
background-color: rgb(53, 57, 86);
}
.token-banner .token-box span{
position: relative;
top: 5px;
font-size: 20px;
}
.token-banner  h1{
position: relative;
right: 95px;
font-size: 66px;
line-height: 72px;  
  font-weight: 700;
text-transform: uppercase;
}
.token-banner p{
position: relative;
right: 95px;
font-size: 20px;
line-height: 30px;
}
.token-banner .token-box .box-home{
color: white;
}
.token-banner .token-box .box_span{
color: #ef8b33;
}
.token-banner .token-box .arrow{
padding: 0px 20px;
}
.token-banner .token-box .arrow img{
width: 30px;
}


/* Media Query for mobile view */
@media (min-width: 769px) and (max-width: 1280px) {
  .plaza-image {
    max-height: 410px;
  }
.token-banner {
  position: relative;
  margin-top: 180px;
  left: 9%;
  padding: 15px;
  width: 80%;
}

.token-banner .token-box {
  position: relative;
  left: 190px;
  min-width: 190px;
  height: 40px;
  border: 2px solid rgb(53, 57, 86);
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
}

.token-banner .token-box span {
  font-size: 18px;
  position: relative;
  top: 6px;
}

.token-banner h1 {
  position: relative;
  right: 0;
  font-size: 48px;
  line-height: 54px;
}

.token-banner p {
  position: relative;
  right: 0;
  font-size: 19px;
  line-height: 24px;
}

.token-banner .token-box .arrow img {
  width: 25px;
}
}

/* Additional styles for very small devices (portrait phones) */
 @media (max-width: 768px) {
  .token-top{
    position: relative;
    top: -20px;
  }

.token-banner {
  position: relative;
  margin-top: 72%;
  padding: 10px;
  width: 90%;
}
.token-banner .token-box {
  width: 200px;
  height: 35px;
  left: 40px;
}

.token-banner .token-box span {
  font-size: 14px;
  position: relative;
  top: 1px;
}

.token-banner h1 {
  font-size: 36px;
  line-height: 42px;
  position: relative;
  left: 0px;
  margin-right: 40px;
}

.token-banner p {
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 0px;
  margin-right: 40px;
}

.token-banner .token-box .arrow {
  width: 10px;
  height: 10px;
}
} 
















.token-top{
  margin-top: 100px;
}
.plaza-image {
  height: 690px;
}
.tags a:link,
.tagsa:visited {
  background-color: white;
  color: black;
  padding: 0px;
  text-align: center;
  text-decoration: none;
}
.token-head{
  padding: 40px 0;
}
.tag1 {
  color: black;
  margin: 7px 9px;
  padding: 5px 10px;
  text-decoration: none;
  display: inline-block;
  border: 2px solid;
}

.tag1:hover {
  background-color: rgb(63, 89, 238);
  border: 2px solid blue;
}
@media (max-width: 768px){
  .token-img{
    width: 100%;
  }
  .token-img1{
    width: 100%;
    height: 500px;
  }
  .token-img2{
    width: 100%;
    height: 200px;
  }
  .token-img3{
    width: 100%;
    height: 150px;
  }
}
/* tokenization css end */

/* form2 css start */
.box19 {
  position: relative;
  top: 110px;
  /* width: 1000px; */

}
.box19 h4 {
  text-align: center;
}
@media (max-width: 768px) {
  .form-check-label{
    font-size: 20px;
  }
}

.form-button {
  width: 150px;
  position: relative;
  margin-left: 480px;
  top: 10px;
  background-color: brown;
  color: #fff;
}
@media (max-width: 768px) {
  .form-button {
  margin-left: 0;
  }
}
@media (min-width: 769px) and (max-width: 1280px) {
  .form-button{
    right: 187px;
  }
} 
/* form2 css end */

/* card css start */
.card-top {
  position: relative;
  top: 100px;
  border: 2px solid blue none;
}
.custom-card {
  position: relative;
  width: 280px;
  height: 400px;
  margin: 60px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.custom-card:hover {
  transform: translateY(-10px);
}
.custom-card .card-image {
  width: 100%;
  height: 60%;
  overflow: hidden;
}
.custom-card .card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.custom-card:hover .card-image img {
  transform: scale(1.1);
}
.custom-card .card-text {
  padding: 20px;
  background-color: #f8f9fa;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (max-width: 768px) {
  .card-top{
    position: relative;
    right: 30px;
  }
}
@media (min-width: 769px) and (max-width: 1280px) {
  .custom-card{
    right: 100px;

  }
}
/* card css end */











/* /Blog Card1 css/ */
.card-title-1 {
  color: #262626;
  font-size: 1.5em;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.small-desc {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  color: #452c2c;
}

.small-desc {
  font-size: 1em;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2em;
  height: 2em;
  overflow: hidden;
  bottom: 0;
  right: 0;
  /* background: linear-gradient(135deg, #6293c8, #384c6c); */
  border-radius: 0 4px 0 32px;
}
.card:hover:before .go-corner {
  background: linear-gradient(135deg, #6293c8, #384c6c);
}
.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.blog-card-1{
  display: block;
  position: relative;
  max-width: 300px;
  max-height: 360px;
  background-color: #f2f8f9;
  border-radius: 10px;
  padding: 2em 1.2em;
  margin: 12px 25px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
  font-family: Arial, Helvetica, sans-serif;
}
.card-img-top1{
  height: 170px;
  width: 100%;
}
.card-img-top3{
  height: 170px;
  width: 100%;
}
.card:before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -16px;
  right: -16px;
  background: linear-gradient(135deg, #6293c8, #384c6c);
  height: 48px;
  width: 49px;
  /* border-radius: 29px; */
  transform: scale(1);
  transform-origin: 60% 50%;
  transition: transform 0.35s ease-out;
}

.card:hover:before {
  transform: scale(28);
}
.blog-card a{
  text-decoration: none;
}
.card:hover .small-desc {
  transition: all 0.5s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card:hover .card-title-1{
  transition: all 0.5s ease-out;
  color: #ffffff;
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .blog-card-1{
    margin-left: 60px;
  }
}
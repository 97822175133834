.bussiness-heading{
  text-align: center;
  margin:40px;
}

.bussiness-inte{
  /* border: 4px solid black; */
  width: 100%;
  /* margin-top: 0px; */
  /* margin-bottom: 80px; */
}
.bussiness-top{
  position: relative;
  top: 90px;
  margin-bottom: 140px;
}


.bussiness-info{
  position: relative;
  text-align:center;
  overflow: hidden;
  box-shadow:5px 0 5px #808080;
  border: 3px solid #808080;
  border-radius: 2px;
  animation: scaleUp 1s ease-in-out;
  /* border: 1px solid black; */
}

.bussiness-info img{
  width: 100%;
  height: 400px;
  transform: scale(1);
  transition: all 0.5s linear 0s; 
  
}
.bussiness-info:hover img{
  transform: scale(1.1);
  transition:transform 0.5s linear 0s;
  box-shadow:5px 0 5px #808080;
}
.bussiness-info .bussiness-content{
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  color: white;
  opacity: 0;
  padding: 40px 20px;
  font-size: 16px;
  border-radius: 2px;
  background: rgba(0,0,0,0.6);
  transition: all 0.1s ease-in-out;
  border: 2px solid rgb(18, 17, 17);


}
.bussiness-info:hover .bussiness-content{
  opacity: 1;
}
.bussiness-content p{
  font-size: 17.3px;
  /* position: relative;
  top: 20px; */
}
.bussiness-info .bussiness-prof{
  position:relative;
  bottom:30px;
}
.containt-bussiness{
  font-size: 9px;
}

@media (max-width: 768px) {
  .bussiness-content p{
    font-size: 15.9px;
  }
}
@media (min-width: 770px) and (max-width: 1280px) {
  .containt-bussiness{
    font-size: 25px;
  }
  .bussiness-content p{
    font-size: 17.3px;
  }
}


.service{
    width: 100%;
    height: 452px;
    margin-top: 20px;
    padding: 0px;
}
.service-image img{

  animation: scaleUp 1s forwards;
}
@keyframes scaleUp {
  from {
      transform: scale(0.5);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 1;
  }
}
/* Make sure the main-img container is positioned relative */
.service-image {
    position: relative;
  }
  
 /* Style the banner_content */
.service-banner {
  position: relative;
  bottom: 465px;
  left: 10%;
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 8px; 
  z-index: 10; 
}
.service-banner .service-box{
  position: relative;
  top: 10px;
  left: 430px;
  border: 2px solid rgb(53, 57, 86);
  width: 240px;
  height: 50px;
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
}
.service-banner .service-box span{
  position: relative;
  top: 5px;
  font-size: 20px;
}
.service-banner  h1{
  position: relative;
  right: 95px;
  font-size: 66px;
  line-height: 72px;  
    font-weight: 700;
  text-transform: uppercase;
}
.service-banner p{
  position: relative;
  right: 95px;
  font-size: 20px;
  line-height: 30px;
}
.service-banner .service-box .box-home{
  color: white;
}
.service-banner .service-box .box_span{
  color: #ef8b33;
}
.service-banner .service-box .arrow{
  padding: 0px 20px;
}
.service-banner .service-box .arrow img{
  width: 30px;
}

  
/* Media Query for mobile view */
@media (min-width: 768px) and (max-width: 1280px) {

  .service-image  img{
    width: 100%;
  }
  .service-banner {
    position: relative;
    margin-top: 180px;
    left: 9%;
    padding: 15px;
    width: 80%;
  }

  .service-banner .service-box {
    position: relative;
    left: 190px;
    min-width: 190px;
    height: 40px;
    border: 2px solid rgb(53, 57, 86);
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }

  .service-banner .service-box span {
    font-size: 16px;
    position: relative;
    top: 6px;
  }

  .service-banner h1 {
    position: relative;
    right: 0;
    font-size: 48px;
    line-height: 54px;
  }

  .service-banner p {
    position: relative;
    right: 0;
    font-size: 16px;
    line-height: 24px;
  }

  .service-banner .service-box .arrow img {
    width: 25px;
  }
}

/* Additional styles for very small devices (portrait phones) */
@media (max-width: 768px) {
  .service{
    height: 0px;
    margin-top: 80px;
  }
  .service-image  img{
    width: 100%;
    min-height: 220px;
  }
  .service-banner {
    position: relative;
    margin-top: 72%;
    padding: 10px;
    width: 90%;
  }
  .service-banner .service-box {
    width: 170px;
    height: 35px;
    top: -5px;
    left: 60px;
  }

  .service-banner .service-box span {
    font-size: 14px;
    position: relative;
    top: 1px;
  }

  .service-banner h1 {
    font-size: 36px;
    line-height: 42px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }

  .service-banner p {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }

  .service-banner .service-box .arrow {
    width: 10px;
    height: 10px;
  }
}
  

.service-line{
    margin-right: 0px;
    margin-left: 0px;
    width: 1260px;
    border: 5px solid red;
}
/* @media (min-width: 320px) and (max-width: 768px) {
    .service-para{
       margin-top: 29px;
        width: 320px;
        height: 400px;
        padding: 10px;
    }
} */

@media (min-width: 770px) and (max-width: 1280px){
    .service{
        height: 300px;
            margin: 0;
            margin-top: 35px;
    }
    .service-para{
        margin-top: 25px;
        /* height: 260px;
        width: 490px; */
    }
    .service-image{
        height: 300px;
  object-fit: cover;

    }
}



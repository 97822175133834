
.interprice-solution-sec{
    /* border: 2px solid red; */
    justify-content: space-around;
    /* margin-top: 70px; */
}
.Esolution-top{
    margin-top: 110px;
  }
.interprice-solution-data{

  border: 1px ridge none;
  transition: transform 0.8s ease;
}

.interprice-solution-image{
    padding: 30px;
    transition: transform 0.3s ease;
}

.interprice-solution-image img{
    width: 100%;
    height: 390px;
    /* padding-top: 30px; */
    animation: slidebottom 1s forwards;
    object-fit: cover;  
}
/* .interprice-solution-data:hover{
  box-shadow: 1px 1px 20px 1px rgba(97, 94, 250, 0.61);
} */
.interprice-solution-data:hover .interprice-solution-image{
  transform: scale(1.1);
}

.interprice-solution-heading{
    font-family: 'Playfair Display';
    padding: 25px;
    text-align: center;
}

.interprice-solution-sec .interprice-solution-para{
    font-size: 21px;
    padding-left: 20px;
    font-family: 'roboto';
    justify-content: center;

}
.interprice-solution-sec{
    padding-left:30px;
    padding-top: 40px;
    animation: slideRight 1s forwards;
    animation: slideOut 1s forwards;
      
}


@media (max-width: 768px){
    .interprice-solution-sec{
        padding-top: 0px;
    }
    .interprice-solution-data{
        position: relative;
        right: 10px;
    }
    .interprice-solution-data:hover .interprice-solution-image{
        transform: scale(1);
      }
}

@media (max-width: 992px){
    .interprice-solution-sec{
        padding-top: 0px;
    }
}

/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  html,body {
    width: 100%;
    height: 8vh;
  } */


  /* @keyframes bounce {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(15px);
    }
  }

  @keyframes slidebottom {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideup {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  } */
  /* @keyframes slideright {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  } */

  /* @keyframes slideleft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  } */
  .blog-main-heading{
    margin-top: 150px;
  }
.blog-heading{
    text-align: left;
    margin-bottom: 12px;
    font-family: 'roboto';
    animation: bounce 1s forwards;
}
 .blog-main-heading h1{
    font-family: roboto;
    text-align: center;
    font-weight: 500px;
}
.blog-section{
    /* border: 2px solid red; */
    justify-content: space-around;
    padding: 20px;
    font-family: 'roboto';  
}
.blog-content{
    /* padding-left:30px; */
    padding: 0px;
    animation: slideright 1s forwards;
}
.blog-content .blog-heading{
    font-family: 'Playfair Display';
    padding-top: 25px;
    text-decoration: underline;
}

.blog-content .blog-para{
    padding-right: 20px;
    font-family: 'roboto';
    font-size: 21px;
}
.blog-sol .blog-para{
    font-family: roboto;
    font-size: 19px;
}

.blog-image img{
    position: relative;
    top: 40px;
    left: 0px;
    /* right: 10px; */
    width: 100%;
    height: 85%;
    animation: slidebottom 1s forwards;
  
} 

.blog-impact-sec{
    animation: bounce 1s forwards;
}

.blog-impact-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.blog-impact-para{
    font-size: 19px;
    padding-left: 20px;
    font-family: 'roboto';
}
 .blog-sol h5{
    font-size: 22px;
    font-family: 'roboto';
    text-decoration: underline;
}
   .blog-sol ul p{
    font-size: 19px;
    font-family: roboto;
} 
.blog-heading-5 {
    text-align: center;
    font-family: roboto;
    font-size: 19px;
}
.blog-5-impact{
    font-family: roboto;
    font-size: 18.8px;
}
.blog-conclusion h3{
    font-family: roboto;
    text-align: center;
    text-decoration: underline;
}
.blog-conclusion p{
    font-family: roboto;
    font-size: 19px;

}

@media (max-width: 768px) {
  .blog-main-heading{
    margin-top: 90px;
  }
  .blog-image img{
    position: relative;
    top: 0;
  }
}
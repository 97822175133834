/* Offer */

.product-section {
    /* background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%); */
    background: #010521;
    position: relative;
    padding: 60px 0;
    margin-top: 100px;
}

.product-section .offer-toplayer {
    position: absolute;
    left: -24px;
    top: 0;
}

.product-section .offer-bottomlayer {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.product-section .offer-circleimage {
    position: absolute;
    left: -146px;
    bottom: 70px;
}

.product-section .offer_boxcontent {
    box-shadow: 1px 1px 50px rgb(0 0 0 / 10%);
    width: 100%;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    background: white;
    transition: all 0.3s ease-in-out;
}

.product-section .offer_boxcontent:hover {
    /* background: #010521; */
     background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
}

.product-section .offer_boxcontent:hover h4 {
    color: white;
}


.product-section .offer_content {
    text-align: center;
}


.product-section .offer_boxcontent .upper_portion {
    position: relative;
}

.product-section .offer_boxcontent .upper_portion  .article_img figure  img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    height: 200px;
}

.product-section .offer_boxcontent .upper_portion .image_content {
    background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
    box-shadow: 8px 2px 20px rgb(0 0 0 / 10%);
    border-radius: 100px;
    height: 105px;
    width: 105px;
    line-height: 105px;
    text-align: center;
    left: 124px;
    bottom: -50px;
    position: absolute;
    transition: all 0.3s ease-in-out;
}

.product-section .offer_boxcontent .upper_portion .image_content:hover {
    background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%);
    transform: translateY(-5px);
}

.product-section .offer_boxcontent .lower_portion_wrapper {
    text-align: center;
    padding: 40px 25px 30px;
    border-radius: 0 0 10px 10px;
    border-top: none;
    height:220px;
    
}

.product-section .offer_boxcontent .lower_portion_wrapper .lower_portion{


    margin-bottom: 20px;
}

.product-section .offer_boxcontent .lower_portion_wrapper:hover {
    color:white;
}

.product-section .offer_boxcontent .lower_portion_wrapper .read_more {
    /* background: linear-gradient(90deg, rgba(250,98,32,1) 0%, rgba(239,139,51,1) 100%); */
     background: #010521;
    font-size: 16px;
    height: 50px;
    line-height: 30px;
    font-weight: 600;
    border-radius: 20px;
    padding: 10px 20px 10px 20px;
    text-align: center;
    display: inline-block;
    transition: all 0.3s ease-in-out;
}
/* .product-section .offer_boxcontent .lower_portion_wrapper .read_more:hover {
    background: #ff6727;
} */
/* .product-section .offer_boxcontent .lower_portion_wrapper .read_more:hover p{
    color: #ecedf7;
} */
.product-section .offer_boxcontent  .lower_portion_wrapper .read_more figure {
    display: inline-block;
    margin-left: 10px;
}
.about-section .about_content .read_more:hover {
    background: #010521;
}


@media only screen and (max-width: 991px) {
    .product-section  {
        padding: 65px 0 70px;
    }
    .product-section  .offer-toplayer img {
        width: 600px;
    }
    .product-section  .offer-bottomlayer img {
        width: 600px;
    }
    /* .product-section  .offer_content h2 {
        margin-bottom: 10px;
    } */
    /* .product-section  .offer_content p {
        margin-bottom: 25px;
        padding: 0 50px;
    } */

    .product-section .card-sec{
        padding-bottom:30px
    }
    .product-section  .offer_boxcontent .upper_portion figure img {
        width: 210px;
    }
    .product-section  .offer_boxcontent .upper_portion .image_content {
        height: 75px;
        width: 75px;
        line-height: 75px;
        left: 68px;
        bottom: -34px;
    }
    .product-section  .offer_boxcontent .upper_portion .image_content figure img {
        width: 40px;
        /* height: 400px; */
    }
    .product-section  .offer_boxcontent .upper_portion .image_content .offer-icon2 img {
        width: 30px;
    }
    .product-section  .offer_boxcontent .lower_portion_wrapper {
        padding: 50px 6px 25px;
        height: 210px;
    }
}

@media only screen and (max-width: 767px) {

        .product-section  {
        padding: 55px 0 0px;
        top: -29px;
    }

    .product-section .card-sec{
        padding-bottom:30px
    }
    .product-section  .offer_content h6 {
        margin-bottom: 0;
    }
    /* .product-section  .offer_content p {
        margin-bottom: 25px;
        padding: 0;
    } */
    .product-section  .offer_boxcontent {
        margin-bottom: 20px;
    }
    .product-section  .offer_boxcontent .upper_portion figure img {
        width: 100%;
    }
    .product-section  .offer_boxcontent .upper_portion .image_content {
        height: 70px;
        width: 70px;
        line-height: 70px;
        left: 150px;
        bottom: -32px;
    }
    .product-section  .offer_boxcontent .upper_portion .image_content figure img {
        width: 40px;
    }
    .product-section  .offer_boxcontent .upper_portion .image_content .offer-icon2 img {
        width: 28px;
    }
    .product-section  .offer_boxcontent .lower_portion_wrapper {
        padding: 50px 10px 25px;
    }
}
.ifile-top {
  position: relative;
  top: 120px;
}

.ifile-solution {
  padding: 0px;
  /* height: 400px; */
}

@keyframes slideright {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}







.ifile-image {
  padding: 0px;
}
.case1-heading {
  text-align: center;
  font-family: "roboto";
  padding: 10px;
  /* animation: bounce 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards; */
}



.company-overview {
  /* border: 5px solid red; */
  position: absolute;
  top: 5%;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  padding-top: 250px;
  height: 450px;
  font-size: 20px;
  opacity: 0;
}

.company-overview:hover {
  opacity: 1;
  color: white;
}

.ifile-image .company-overview li {
  font-family: "roboto";
  font-size: 23px;
}
.company-heading {
  font-family: "playfair Display";
}

.background {
  /* border: 2px solid red; */
  justify-content: space-around;
  /* margin-top: 70px; */
}

.case-image {
  /* padding-left:35px ; */
  /* padding-right: 25px; */
  padding-top: 40px;
  /* width: 100%; */
}

.case-image img {
  width: 100%;

}



.background-content .background-heading {
  font-family: "Playfair Display";
  padding-top: 25px;
  text-decoration: underline;
}

.background-content .background-para {
  font-size: 23px;
  padding-right: 20px;
  font-family: "roboto";
}
.background-content {
  padding-left: 30px;
  padding-top: 10px;
}



.challenge {
  padding: 20px 40px;
}

.challenge-heading {
  font-family: "Playfair Display";
  text-decoration: underline;
}

.challenge li {
  font-size: 23px;
  font-family: "roboto";
}

.car-solution {
  padding: 20px 30px;
}

.car-solution .ifile-para {
  padding-top: 10px;
  font-size: 23px;
  font-family: "roboto";
}

.car-solution .solution-heading {
  font-family: "Playfair Display";
  text-decoration: underline;
}

.feature-heading {
  text-align: center;
  font-family: "Playfair Display";
}

.ifile-feature {
  padding: 10px;
}

.ifile-feature ol {
  line-height: 2;
  font-size: 23px;
  font-family: "roboto";
  padding-left: 30px;
}

.imple-process {
  padding: 20px 40px;
}

.imple-process ol {
  font-size: 23px;
  font-family: "roboto";
}
.process-heading {
  font-family: "Playfair Display";
}

.result {
  padding: 20px 40px;
}

.result ul {
  font-size: 23px;
  font-family: "roboto";
}

.result-heading {
  font-family: "Playfair Display";
  text-decoration: underline;
}

/* .conclusion {
  animation: slidebottom 1s forwards;
} */
.conclusion-heading {
  font-family: "Playfair Display";
  text-decoration: underline;
}

.conclusion-para {
  font-size: 23px;
  font-family: "roboto";
}

@media (max-width: 768px) {
  .ifile-top {
    margin-top: -40px;
  }

  .background-content {
    /* border: 3px solid green; */
    padding-left: 30px;
    padding-top: 10px;
  }

  .car-solution {
    /* border: 2px solid red; */
    padding: 20px;
  }
}





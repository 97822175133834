/* Service */

.service-section {
  margin-top: 40px;
  position: relative;
  padding: 90px 0 10px;
  overflow: hidden;
}

.service-section .service-leftlayer {
  position: absolute;
  top: 0;
  left: 60px;
  z-index: -1;
}

.service-section .service-rightlayer {
  position: absolute;
  bottom: -20px;
  right: 112px;
}

.service-section .service-dotimage {
  position: absolute;
  left: 0px;
  bottom: 102px;
  z-index: -1;
}

.service-marketicon .img-fluid {
  height: 80px;
  width: 80px;
}
.service-producticon .img-fluid {
  height: 80px;
  width: 80px;
}
.service-designicon .img-fluid {
  height: 80px;
  width: 80px;
}
.service-dataicon .img-fluid {
  height: 80px;
  width: 80px;
}
.service-section .service-box h4 {
  /* padding-right: 65px; */

  color: #202020;
}

.service-section .service-box p {
  margin-bottom: 10px;
  color: #656565;
}

.service-section .service_contentbox .box-top {
  padding-top: 37px;
}
.service_contentbox .our-products-heading {
  color: #ef8b33;
}

.service-section .service_contentbox .service-box {
  background: white;
  border-radius: 10px;
  padding: 38px 24px 28px 30px;
  margin-bottom: 12px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  box-shadow: 1px 21px 46px rgb(0 0 0 / 4%);
}

.service-section .service_contentbox .service-box:hover {
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 1) 0%,
    rgba(239, 139, 51, 1) 100%
  );
  transform: translateY(-5px);
}

.service-section .service-box:hover h4 {
  color: white;
}

.service-section .service-box:hover p {
  color: white;
}

.service-section .service-box:hover .circle {
  color: #ef8b33;
}

.service-section .service_contentbox .service-box figure {
  background: #e0d7cf;
  border-radius: 100px;
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  margin-bottom: 20px;
  display: inline-block;
  margin-left: 4px;
  transition: all 0.3s ease-in-out;
}

.service-section .service_contentbox .service-box figure:hover {
  transform: translateY(-5px);
}

.service-section .service-box:hover figure {
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 1) 0%,
    rgba(239, 139, 51, 1) 100%
  );
}

.service-section .service-box:hover img {
  filter: brightness(0) invert(1);
}

.service-section .service-box .circle {
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 1) 0%,
    rgba(239, 139, 51, 1) 100%
  );
  border-radius: 100px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  margin-left: -2px;
  text-align: center;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.service-section .service-box .circle:hover {
  color: #ef8b33;
  transform: translateY(-5px);
}

.service-section .service-box:hover .circle {
  background-image: linear-gradient(
    to right,
    #ffffff 0%,
    #ffffff 51%,
    #ffffff 100%
  );
}

.service-section .service-box .circle:before {
  font-size: 18px;
  padding-top: 2px;
  font-family: "FontAwesome";
  transition: all 0.3s ease;
  color: white;
}

.service-section .service-box:hover .circle:before {
  color: var(--e-global-color-accent);
}

.service-section .service_content .service-rightcircle {
  position: absolute;
  right: -38px;
  top: 162px;
}

.service-section .service_content {
  padding-left: 48px;
  padding-top: 194px;
}

.service-section .service_content h2 {
  margin-bottom: 18px;
  padding-right: 10px;
  color: inherit;
}

.service-section .service_content h3 {
  color: #ef8b33;
}
.service-section .service_content h4 {
  margin-right: 290px;
}
.service-section .service_content p {
  margin-bottom: 12px;
  color: #656565;
}

.service-section .service_content .text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #656565;
}

.service-section .service_content .text:hover {
  color: #ef8b33;
}

.service-section .service_content ul li .circle {
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 1) 0%,
    rgba(239, 139, 51, 1) 100%
  );
  border-radius: 100px;
  height: 18px;
  width: 18px;

  line-height: 18px;
  text-align: center;
  margin-right: 12px;
  margin-left: 0;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.service-section .service_content ul li .circle:before {
  font-size: 12px;
  line-height: 20px;
  padding-top: 2px;
  font-family: "FontAwesome";
  transition: all 0.3s ease;
  color: #656565;
}

.service-section .service_content .text1 {
  margin-bottom: 36px;
}

.service-section .service_content .get_started {
  background: linear-gradient(
    90deg,
    rgba(250, 98, 32, 1) 0%,
    rgba(239, 139, 51, 1) 100%
  );
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  border-radius: 30px;
  padding: 15px 25px 15px 43px;
  text-align: center;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.service-section .service_content .get_started a {
  text-decoration: none;
  color: white;
}
.service-section .service_content .get_started figure {
  display: inline-block;
  margin-left: 16px;
}

.service-section .service_content .get_started:hover {
  background: #353956;
}

@media (max-width: 768px) {
  .service-section {
    margin-top: 40px;
  }
  .service-section .service-dotimage {
    position: absolute;
    position: relative;
    left: 0px;
    top: 1650px;
    z-index: -1;
    display: none;
  }

  .service-section .service-rightlayer {
    position: absolute;
    bottom: -20px;
    right: -70px;
    height: 80;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .service-section {
    position: relative;
    padding: 0px 0 80px;

    overflow: hidden;
  }
  .service-section .service-dotimage {
    position: relative;
    left: 0px;
    top: 880px;
    z-index: -1;
  }

  .service-section .service-rightlayer {
    position: absolute;
    bottom: -20px;
    right: 30px;
    height: 80;
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .service-section {
    padding: 70px 0;
  }
  .service-section .service-rightlayer {
    display: none;
  }
  .service-section .service-dotimage {
    display: none;
  }
  .service-section .service_content h6 {
    margin-bottom: 4px;
  }
  .service-section .service_content .text {
    font-size: 16px;
    line-height: 24px;
  }
  .service-section .service_contentbox {
    position: relative;
    z-index: 0;
  }
  .service-section .service_contentbox .service-box {
    padding: 20px;
    margin-bottom: -20px;
    text-align: center;
  }
  .service-section .service-box h4 {
    padding-right: 0;
    margin-bottom: 4px;
  }
  .service-section .service-box p {
    margin-bottom: 14px;
  }
  .service-section .service_contentbox .service-box figure {
    height: 85px;
    width: 85px;
    line-height: 85px;
    margin-bottom: 10px;
  }
  .service-section .service_contentbox .service-box figure img {
    width: 50px;
  }
  .service-section .service-box .circle {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
  .service-section .service-box .circle:before {
    font-size: 16px;
    padding-top: 0;
  }
  .service-section .service_content {
    padding-top: 85px;
    text-align: center;
  }
  .service-section .service_content .service-rightcircle {
    top: 100px;
  }
  .service-section .service_content h2 {
    margin-bottom: 10px;
    padding: 0 154px;
  }
  .service-section .service_content p {
    margin-bottom: 10px;
    padding: 0 60px;
  }
  .service-section .service_content .text1 {
    margin-bottom: 25px;
  }
  .service-section .service_content ul li .circle {
    display: none;
  }
  .service-section .service_content .get_started {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 16px;
  }
}

@media (max-width: 767px) {
  .service-section {
    padding: 60px 0;
  }
  .service-section .service-leftlayer img {
    width: 140px;
  }
  .service-section .service_contentbox .service-box {
    padding: 15px 10px;
    margin-bottom: 18px;
  }
  .service-section .service_contentbox .service-box figure {
    height: 80px;
    width: 80px;
    line-height: 80px;
    margin-bottom: 10px;
  }
  .service-section .service_contentbox .service-box figure img {
    width: 45px;
  }
  .service-section .service-box p {
    margin-bottom: 15px;
  }
  .service-section .service-box .circle {
    height: 35px;
    width: 35px;
    line-height: 35px;
  }
  .service-section .service-box .circle:before {
    font-size: 14px;
  }
  .service-section .service_content {
    padding-top: 50px;
    padding-left: 10px;
  }
  .service-section .service_content h2 {
    padding: 0 0px;
  }
  .service-section .service_content p {
    margin-bottom: 10px;
    padding: 0;
  }
  .service-section .service_content ul {
    padding-left: 10px;
    line-height: 20px;
  }
  .service-section .service_content .text {
    font-size: 14px;
    line-height: 30px;
  }
  .service-section .service_content .text1 {
    margin-bottom: 18px;
  }
  .service-section .service_content .get_started {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 24px;
  }
  .service-section .service_content .get_started figure {
    margin-left: 4px;
  }
  .service-section .service_content .get_started figure img {
    width: 16px;
  }
  .service-section .service_content .service-rightcircle img {
    width: 35px;
    position: relative;
    right: 50px;
    bottom: 100px;
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .service-section .service-leftlayer {
    left: 2% !important;
  }
  .service-section .service-dotimage {
    left: 0px;
  }
}
@media (min-width:769px) and (max-width:1280px) {
.service-section .service_content h4 {
  margin-right: 0px;
}
}

  .plaza-top{
    position: relative;
    top: 120px;
}
.plaza-solution{
    padding:0px;
    /* height: 400px; */
}

.plaza-image img{
    width: 100%;
    /* height: 450px; */
    animation: slideRight 1s forwards;
}
.plaza-solution {
  animation: slideright 1s forwards;
  background-color: #ef8b33;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding: 277px 0 px;
  height: 600px;
}
.plaza-solution::before {
  content: "";
  width: 90%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 0px 0 400px;
  background-color: #010521;
}

.plaza-solution::after {
  content: "";
  background-image: url('/public/case-study-img/tollplazaintro.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0px 0 400px;
  width:90%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 10%;
}
.plaza-banner-circleright{
  position: relative;
  width: 200px;
  top: -330px;
  right:220px;

}
.plaza-banner-circle{
  position: relative;
  width: 60px;
  top: -260px;
  left:1080px;
}



/* Style the banner_content */
.plaza-banner {
  position: relative;
  bottom: -180px;
  left: 10%;
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 8px; 
  z-index: 10; 
  }
  .plaza-banner .plaza-box{
  position: absolute;
  top: 160px;
  left: 460px;
  border: 2px solid rgb(53, 57, 86);
  width: 260px;
  height: 50px;
  border-radius: 35px;
  background-color: rgb(53, 57, 86);
  }
  .plaza-banner .plaza-box span{
  position: relative;
  top: 5px;
  font-size: 20px;
  }
  .plaza-banner  h1{
  position: relative;
  right: 95px;
  font-size: 66px;
  line-height: 72px;  
    font-weight: 700;
  text-transform: uppercase;
  }
  .plaza-banner p{
  position: relative;
  right: 95px;
  font-size: 20px;
  line-height: 30px;
  }
  .plaza-banner .plaza-box .box-home{
  color: white;
  }
  .plaza-banner .plaza-box .box_span{
  color: #ef8b33;
  }
  .plaza-banner .plaza-box .arrow{
  padding: 0px 20px;
  }
  .plaza-banner .plaza-box .arrow img{
  width: 30px;
  }
  
  
  /* Media Query for mobile view */
  @media (min-width: 769px) and (max-width: 1280px) {
    .plaza-solution{
      margin-top: -30px;
      height: 550px;
    }
  .plaza-banner {
    position: relative;
    margin-top: 20px;
    left: 12%;
    padding: 15px;
    width: 80%;
  }
  
  .plaza-banner .plaza-box {
    position: absolute;
    left: 190px;
    min-width: 190px;
    height: 40px;
    border: 2px solid rgb(53, 57, 86);
    border-radius: 35px;
    background-color: rgb(53, 57, 86);
  }
  
  .plaza-banner .plaza-box span {
    font-size: 16px;
    position: relative;
    top: 6px;
  }
  
  .plaza-banner h1 {
    position: relative;
    right: 0;
    font-size: 48px;
    line-height: 54px;
  }
  
  .plaza-banner p {
    position: relative;
    right: 0;
    font-size: 19px;
    line-height: 24px;
  }
  
  .plaza-banner .plaza-box .arrow img {
    width: 25px;
  }
  .plaza-banner-circleright{
    position: relative;
    top: -300px;
    right:210px;
  
  }
  .plaza-banner-circle{
    position: absolute;
    top: 250px;
    left:600px;
  }
  }
  
  /* Additional styles for very small devices (portrait phones) */
   @media (max-width: 768px) {
    .plaza-top{
      position: relative;
      top: 75px;
  }
    .plaza-solution{
      height: 300px;
    }
  .plaza-banner {
    position: relative;
    margin-top: -34%;
    padding: 10px;
    width: 90%;
  }
  .plaza-banner .plaza-box {
    width: 210px;
    height: 35px;
    top: 140px;
    left: 40px;
  }
  
  .plaza-banner .plaza-box span {
    font-size: 14px;
    position: relative;
    top: 1px;
  }
  
  .plaza-banner h1 {
    font-size: 36px;
    line-height: 42px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }
  
  .plaza-banner p {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px;
    margin-right: 40px;
  }
  
  .plaza-banner .plaza-box .arrow {
    width: 10px;
    height: 10px;
  }
  .plaza-banner-circleright{
    position: relative;
    top: -190px;
    width: 100px;
    left: -90px;
  }
  .plaza-banner-circle{
    position: absolute;
    top: 180px;
    width: 50px;
    height: 5px;
    left: 260px;
  }
  } 








.plaza-image{
  padding: 0%;
  
}
.case2-heading{
    text-align: center;
    padding: 10px;
    font-family: 'roboto';

}


.intro-plaza{
    /* border: 2px solid red; */
    justify-content: space-around;
    padding: 20px;
    font-family: 'roboto';
}


.intro-content .intro-heading{
    font-family: 'Playfair Display';
    /* padding-top: 25px; */
    text-decoration: underline;
}

.intro-content .intro-para{
    /* font-size: 20px; */
    padding-right: 20px;
    font-family: 'roboto';
    font-size: 23px;
}

.intro-content{
    padding-left:30px;
    padding-top: 20px;

    
}


.background-plaza{
    /* border: 4px solid black; */
    padding-top: 20px;

}
.plazaback-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.plaza-para{
    font-size: 23px;
    padding-left: 20px;
    font-family: 'roboto';
}


.plaza-image2{
    /* padding-left:35px ;
    padding-right: 25px; */
    padding-top: 20px;
    /* width: 100%; */
}

.plaza-image2 img{
    width: 100%;
    height: 100%;
  
}

.tnms-solution{
    padding: 30px;
}

.tnms{
    padding-left: 30px;
    font-size: 23px;
    font-family: 'roboto';
}

.tnms-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.tnms li{
    font-size: 23px;
    font-family: 'roboto';
}


.tnms-para{
    font-size: 23px;
    font-family: 'roboto';
}


.implementation{
    padding-left: 30px;

}

.implementation-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.implementation li{
    font-size: 23px;
    font-family: 'roboto';
}


.implementation-para{
    font-size: 23px;
    font-family: 'roboto';
}

.impact-info{
    padding: 20px 30px;
    /* border:3px solid black; */
}

.impact-content{
    padding-left: 30px;
  
}

.impact-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.impact-content li{
    font-size: 23px;
    font-family: 'roboto';
}


.impact-para{
    font-size: 23px;
    font-family: 'roboto';
}


.challenge-solution{
    padding-left: 30px;

}

.cs-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.challenge-solution li{
    font-size: 23px;
    font-family: 'roboto';
}


.cs-para{
    font-size: 23px;
    font-family: 'roboto';
}

.future{
    padding: 20px 30px;
}

.future-para{
    font-size: 23px;
    font-family: 'roboto';
}

.future-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.future-prospects li{
    font-size: 23px;
    font-family: 'roboto';
}

.future-prospects{
    padding-left: 30px;
}

.pc-heading{
    font-family: 'Playfair Display';
    text-decoration: underline;
}

.pc-para{
    font-size: 23px;
    font-family: 'roboto';
}
